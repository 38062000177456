.industries_overview_main_container {
  width: 100%;
  /* max-width: 1440px; */
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding: 0 15px;
  margin-top: 30px;
}

.industries_overview_title {
  position: relative;
}

.industries_overview_title::before {
  content: "";
  background: url(../../../assets/icons/indutry_section_circle.svg) no-repeat;
  width: 241px;
  height: 241px;
  position: absolute;
  top: 20%;
  left: 0%;
  opacity: 15%;
}
.industries_overview_title::after {
  content: "";
  background: url(../../../assets/icons/indutry_section_horse_icon.svg)
    no-repeat;
  width: 140px;
  background-position: right;
  height: 180px;
  position: absolute;
  top: 156%;
  right: 0%;
}

.industries_overview_title p {
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  color: var(--TopBar-Light-Green);
  margin: 0 auto -30px;
  text-transform: uppercase;
}

.industries_overview_title h3 {
  color: var(--black);
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  align-items: center;
}
.industries_overview_content{
  width: 90%;
  margin: 0 auto;
}

.industries_overview_content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--gray);
  text-align: justify;
  justify-content: center;
}

/* Responsive Styles */

@media (max-width: 1024px) {
  .industries_overview_title::before {
    display: none;
  }
  .industries_overview_title::after {
    display: none;
  }
}
@media (max-width: 768px) {
  .industries_overview_title h3 {
    font-size: 32px;
    line-height: 36px;
  }

  .industries_overview_title p {
    font-size: 16px;
    margin: 0 auto -20px;
  }

  .industries_overview_content p {
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
    padding: 0px 10px 0px 10px;
  }

  .industries_overview_title::before {
    width: 300px;
    height: 400px;
    left: -5%;
    top: 0;
  }
  .industries_overview_title::before {
    display: none;
  }
}

@media (max-width: 480px) {
  .industries_overview_title h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .industries_overview_title p {
    font-size: 14px;
    margin: 0 auto -15px;
  }

  .industries_overview_content p {
    font-size: 14px;
    line-height: 20px;
  }

  .industries_overview_title::before {
    width: 200px;
    height: 300px;
    left: -2%;
    top: -10%;
  }
}

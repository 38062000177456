/* .choose_us_main_container {
  max-width: 1440px;
  width: 100%;
  height: 420px;
  background-color: var(--white);
  margin: 0 auto;
  z-index: 10000;
  position: relative;
}
.choose_us_container {
  width: 100%;
  max-width: 1160px;
  height: 450px;
  margin: 0 auto;
  margin-top: -85px;
  z-index: 100;
  top: -11%;
  left: 7%;
  position: absolute;
  background: linear-gradient(112.52deg, #72b01d 2.4%, #3f7d20 61.09%);
  border-radius: 50px 0px 50px 0px;
}

.choose_us_container::before {
  content: "";
  background: url(../../../assets/icons/choose_us_section_dots.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 400px;
  height: 470px;
  position: absolute;
  left: -3%;
}
.choose_us_container::after {
  content: "";
  background: url(../../../assets/icons/choose_us_section_round.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 300px;
  position: absolute;
  left: 74%;
  top: -2%;
}

.choose_us_title {
  gap: 8px;
  text-align: center;
  margin-top: 50px;
}

.choose_us_title p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--white);
  margin: 0;
  text-transform: uppercase;
}
.choose_us_title h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  color: var(--white);
  margin: 0;
}
.choose_us_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.choose_us_content ul {
  gap: 24px;
}
.choose_us_content ul li {
  font-size: 20px;
  font-weight: 400;
  line-height: 48px;
  color: var(--white);
}
@media (max-width: 1024px) {
  .choose_us_container::before {
    display: none;
  }
  .choose_us_container::after {
    display: none;
  }
} */
.choose_us_main_container {
  /* max-width: 1440px; */
  width: 100%;
  height: auto;
  background-color: var(--white);
  margin: 0 auto;
  z-index: 10000;
  height: 450px;
}

.choose_us_container {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  margin-top: -140px;
  z-index: 100;
  position: relative;
  background: linear-gradient(112.52deg, #72b01d 2.4%, #3f7d20 61.09%);
  border-radius: 50px 0 50px 0;
  padding: 2px;
}

.choose_us_container::before {
  content: "";
  background: url(../../../assets/icons/choose_us_section_dots.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 400px;
  height: 100%;
  position: absolute;
  left: -3%;
  top: 1%;
}

.choose_us_container::after {
  content: "";
  background: url(../../../assets/icons/choose_us_section_round.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 26%;
  height: 100%;
  position: absolute;
  left: 74%;
  top: -12%;
}

.choose_us_title {
  text-align: center;
  margin: 0;
}

.choose_us_title p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--white);
}

.choose_us_title h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: var(--white);
  margin: 0;
}

.choose_us_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.choose_us_content ul li {
  font-size: 20px;
  font-weight: 400;
  line-height: 48px;
  color: var(--white);
}

/* ============================================= ADD MEDIA QUERY ================================= */
@media (max-width: 1024px) {
  .choose_us_container {
    margin: 0 auto;
    margin-top: inherit;
    height: 350px;
  }
  .choose_us_main_container {
    height: 350px;
  }

  .choose_us_title h3 {
    font-size: 28px;
  }

  .choose_us_content ul li {
    font-size: 18px;
    line-height: 36px;
  }

  .choose_us_container::before {
    width: 300px;
    height: 350px;
  }

  .choose_us_container::after {
    width: 250px;
    height: 250px;
  }
  .choose_us_container::before {
    display: none;
  }

  .choose_us_container::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .choose_us_container {
    margin-top: inherit;
     
  }

  .choose_us_title p {
    font-size: 14px;
  }

  .choose_us_title h3 {
    font-size: 24px;
  }

  .choose_us_content ul li {
    font-size: 16px;
    line-height: 30px;
  }

  .choose_us_container::before,
  .choose_us_container::after {
    display: none;
  }
}

@media (max-width: 480px) {
  .choose_us_container {
    margin-top: inherit;
   
  }

  .choose_us_title {
    margin-top: 30px;
  }

  .choose_us_title p {
    font-size: 12px;
  }

  .choose_us_title h3 {
    font-size: 20px;
  }

  .choose_us_content ul li {
    font-size: 14px;
    line-height: 28px;
  }
}

* {
  box-sizing: border-box;
}

.footer_section_main_container {
  width: 100%;
  /* max-width: 1440px; */
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  background: var(--footer-bg);
  color: var(--white);
  padding: 20px 60px;
  justify-content: space-between;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin-left: 50px;
}

.footer-section_header {
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
}

.footer-section ul {
  list-style-type: none;
  text-decoration: none;
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 16px 0;
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  list-style: none;
  text-decoration: none;
  line-height: 1.5;
}

.footer-section ul li:hover {
  color: var(--TopBar-Light-Green);
  transition: 0.5s;
}

.footer-section_header_ul_li_main.active {
  color: var(--TopBar-Light-Green);
}

.footer-section_header_ul_li.active {
  color: var(--TopBar-Light-Green);
  font-weight: bold;
}

.footer-section_icons {
  display: flex;
  gap: 12px;
  margin-top: 10px;
}

.footer-section_icons_box1,
.footer-section_icons_box2,
.footer-section_icons_box3,
.footer-section_icons_box4,
.footer-section_icons_box5 {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--white);
  position: relative;
  cursor: pointer;
}

.footer-section_icons_box1:hover,
.footer-section_icons_box2:hover,
.footer-section_icons_box3:hover,
.footer-section_icons_box4:hover,
.footer-section_icons_box5:hover {
  background-color: var(--TopBar-Light-Green);
}

/* Hover Effect on icon : */

.footer-section_icons_box1.active,
.footer-section_icons_box2.active,
.footer-section_icons_box3.active,
.footer-section_icons_box4.active,
.footer-section_icons_box5.active {
  background-color: var(--TopBar-Light-Green);
}

.footer-section_icons_box1.active img,
.footer-section_icons_box2.active img,
.footer-section_icons_box3.active img,
.footer-section_icons_box4.active img,
.footer-section_icons_box5.active img {
  filter: brightness(0) invert(1);
}

.footer-section_icons_box1:hover,
.footer-section_icons_box2:hover,
.footer-section_icons_box3:hover,
.footer-section_icons_box4:hover,
.footer-section_icons_box5:hover {
  background-color: var(--TopBar-Light-Green);
  transition: 0.5s;
}

.footer-section_icons_box1:hover img,
.footer-section_icons_box2:hover img,
.footer-section_icons_box3:hover img,
.footer-section_icons_box4:hover img,
.footer-section_icons_box5:hover img {
  filter: brightness(0) invert(1);
  transition: 0.5s;
}

.footer-section_icons_box1 img {
  position: absolute;
  top: 25%;
  left: 35%;
}
.footer-section_icons_box2 img {
  position: absolute;
  top: 32%;
  left: 32%;
}
.footer-section_icons_box3 img {
  position: absolute;
  top: 25%;
  left: 25%;
}
.footer-section_icons_box4 img {
  position: absolute;
  top: 30%;
  left: 22%;
}
.footer-section_icons_box5 img {
  position: absolute;
  top: 30%;
  left: 28%;
}
.footer_logo {
  margin-top: 20px;
  width: 184.65px;
  height: 80px;
  cursor: pointer;
}

.footer-section_header_ul_li_main span {
  margin-left: 10px;
}
.footer-section_header_ul_li span {
  margin-left: 10px;
}

.footer_section1_content_location img {
  margin: auto;
  margin-top: 0px;
}
.footer_section1_content_location span {
  margin-left: 20px;
}

.footer_section1_content_calling img {
  margin-top: 0px; 
}
.footer_section1_content_calling span {
  margin-left: 20px;
}

.footer_copyright_section {
  display: flex;
  flex-direction: column;
  color: var(--white);
}

.footer_copyright_section {
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-wrap: wrap;
  background: var(--footer-bg);
  color: var(--white);
  padding: 25px 50px;
  margin: 0 auto;
}
.footer_section_horizonatalline {
  color: var(--white);
  width: 100%;
  margin-left: 0px;
}
.footer_secion_copyright_content {
  margin-top: 15px;
}

/* ==============================FOOTER SECTION ADD MEDIA QUERY======================= */
@media (max-width: 999px) {
  .footer_section_main_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .footer-section {
    padding: 0px 20px 0px 50px;
    margin-left: 40px;
  }
}

@media (max-width: 768px) {
  .footer_section_main_container {
    display: grid;
    justify-content: center;
    gap: 10px;
  }
  .footer_secion_copyright_content {
    display: flex;
    flex-direction: column;
  }
  .footer-section {
    margin-left: -25px;
  }
}

@media (max-width: 640px) {
  .footer_section_main_container {
    grid-template-columns: repeat(1, 1fr);
  }
  .footer-section {
    margin-left: -50px;
  }
  .footer_section_main_container {
    padding: 18px 33px;
  }

  .footer_section1_content_location span {
    margin-left: 12px;
  }

  .footer_section1_content_calling span {
    margin-left: 12px;
  }
}
@media (max-width: 425px) {
  .footer_section_main_container {
    padding: 15px 30px;
  }

  .footer_section1_content_location span {
    margin-left: 12px;
  }

  .footer_section1_content_calling span {
    margin-left: 12px;
  }
}
/* add css  */

.footer_section1_content_calling img {
  color: var(--TopBar-Light-Green);
}

/* Hover effect for calling icon */
.footer_section1_content_calling:hover img {
  transition: 0.6s;
}

.footer_section1_content_calling:hover span {
  color: var(--TopBar-Light-Green);
  transition: 0.6s;
}

.footer_section1_content_location:hover img {
  transition: 0.6s;
}

.footer_section1_content_location:hover span {
  color: var(--TopBar-Light-Green);
  transition: 0.6s;
}

/* add css  */

.footer_section1_content_calling img,
.footer_section1_content_location img,
.footer_section1_content_calling span,
.footer_section1_content_location span {
  transition: color 0.5s ease, transform 0.5s ease;
}

.footer_section1_content_calling:hover img {
  transform: scale(1.1);
}

.footer_section1_content_calling:hover span {
  color: var(--TopBar-Light-Green);
}

.footer_section1_content_location:hover img {
  transform: scale(1.1);
}

.footer_section1_content_location:hover span {
  color: var(--TopBar-Light-Green);
}

.footer_section1_content_calling img,
.footer_section1_content_location img {
  display: inline-block;
  vertical-align: middle;
}

.footer_section1_content_calling span,
.footer_section1_content_location span {
  display: inline-block;
  vertical-align: middle;
  /* text-align: justify; */
}

.our_core_services_main_container {
  /* max-width: 1440px; */
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
}

.our_core_services_p {
  font-weight: 700;
  font-size: 14px;
  line-height: 22.4px;
  color: var(--TopBar-Light-Green);
  text-transform: uppercase;
  text-align: center;
}

.our_core_services_h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  margin-top: -3px;
}

.our_core_services_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  position: relative;
}

.our_core_services_card {
  max-width: 25%;
  padding: 15px;
  text-align: center;
  flex: 1 1 auto;
  margin: 10px;
}

.our_core_services_card_icon {
  width: 50px;
  height: 50px;
}

.our_core_services_card h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: var(--black);
  margin-top: 15px;
}

.our_core_services_card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--gray);
  text-align: center;
  margin-top: -20px;
}

.our_core_services_card_arrow {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.5s;
}

.our_core_services_card_arrow:hover {
  /* transform: scale(1.2); */
  filter: brightness(1.2);
  background-color: rgba(63, 125, 32, 0.1);
  box-shadow: 0.5px 0.5px 5px 5px var(--TopBar-Light-Green);
  border-radius: 100%;
}

.vertical_line1,
.vertical_line2 {
  height: auto;
  position: absolute;
  width: 1px;
  background-color: var(--gray);
}

.vertical_line1 {
  left: 36%;
}

.vertical_line2 {
  right: 36%;
}

.horizontal_line1 {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
}

.our_core_services_cards::before,
.our_core_services_cards::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  position: absolute;
  width: 100px;
  height: 100px;
  opacity: 50%;
  z-index: -1;
}

.our_core_services_cards::before {
  content: "";
  display: block;
  background-image: url(../../../assets/icons/services/top_dots.svg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0%;
  top: -20%;
  width: 443px;
  height: 590px;
  opacity: 50%;
  z-index: -1;
}

.our_core_services_cards::after {
  content: "";
  display: block;
  background-image: url(../../../assets/icons/services/bottom_dots.svg);
  background-repeat: no-repeat;
  position: absolute;
  right: 0%;
  bottom: -23%;
  width: 443px;
  height: 590px;
  opacity: 50%;
}

@media (max-width: 1250px) {
  .our_core_services_cards::after,
  .our_core_services_cards::before {
    display: none;
  }
}

@media (max-width: 1024px) {
  .horizontal_line1,
  .vertical_line1,
  .vertical_line2 {
    display: none;
  }
  .our_core_services_card h6 {
    font-weight: 600;
    font-size: 18px;
  }

  .our_core_services_card p {
    font-weight: 400;
    font-size: 16px;
  }
}
/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .our_core_services_h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .our_core_services_card{
    max-width: 40%;
  }
}
@media (max-width: 425px) {
  .our_core_services_cards {
    gap: 0px;
  }

  .our_core_services_card {
    margin-top: 1px;
    text-align: center;
    flex: 1 1 auto;
  }
  .our_core_services_card p {
    margin-top: -25px;
    text-align: center;
  }
  .our_core_services_card {
    max-width: 95%;
  }
  .our_core_services_card h6 {
    font-weight: 600;
    font-size: 16px;
  }

  .our_core_services_card p {
    font-weight: 400;
    font-size: 14px;
  }
}

.contactus_talk_main_container {
  width: 100%;
  max-width: 1160px;
  height: 100%;
  margin: 0 auto;
  margin-top: 50px;
  gap: 50px;
  text-align: center;
  padding: 20px;
}
.contactus_talk_title {
  text-align: center;
}
.contactus_talk_title p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--TopBar-Light-Green);
  margin: 0 auto;
}
.contactus_talk_title h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  color: var(--black);
  margin: 0 auto;
}

.contactus_talk_content {
  width: 100%;
  height: 402.02px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.contactus_talk_content_1,
.contactus_talk_content_2,
.contactus_talk_content_3 {
  width: 382px;
  height: 350px;
  background-color: var(--TopBar-Dark-Green);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.6s;
}

.contactus_talk_content_1:hover,
.contactus_talk_content_2:hover,
.contactus_talk_content_3:hover {
  width: 396px;
  height: 402.02px;
}

.contactus_talk_content_1:hover {
  background: linear-gradient(rgba(114, 176, 29, 0.6), rgba(114, 176, 29, 0.6)),
    url("../../../assets/images/contactus_location_bg_image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contactus_talk_content_3:hover {
  background: linear-gradient(rgba(114, 176, 29, 0.6), rgba(114, 176, 29, 0.6)),
    url("../../../assets/images/contactus_location_bg_image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contactus_talk_content_1_image,
.contactus_talk_content_2_image,
.contactus_talk_content_3_image {
  width: 72px;
  height: 72px;
  background-color: var(--white);
  margin: 0 auto;
  border-radius: 100%;
  position: relative;
}

.contactus_talk_content_1_image img,
.contactus_talk_content_2_image img,
.contactus_talk_content_3_image img {
  position: absolute;
}

.contactus_talk_content_1_image img {
  top: 33%;
  left: 25%;
}

.contactus_talk_content_2_image img {
  top: 22%;
  left: 29%;
}

.contactus_talk_content_3_image img {
  top: 30%;
  left: 26%;
}

/* Title & Text */
.contactus_talk_content_1_title h6,
.contactus_talk_content_2_title h6,
.contactus_talk_content_3_title h6 {
  font-size: 24px;
  font-weight: 700;
  color: var(--white);
  line-height: 28.13px;
  color: var(--white);
  margin: 0 auto;
  margin-top: 18px;
}

.contactus_talk_content_1_title p {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: var(--white);
}
.contactus_talk_content_2_title p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: center;
  color: var(--white);
}

.contactus_talk_content_3_title p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: center;
  color: var(--white);
}

.contactus_talk_map_section .map_section_right  {

  width: 80%;
  margin: 0 auto; 
  align-items: center;
  border: 0px;
  padding-bottom: 5%;
}

.contactus_talk_map_section .map_section_right iframe {
width: 100%;
height: 400px;
border: none;

}

/* loader  */

 

/* =============================================ADD MEDIA QUERY===================================== */

@media (max-width: 1024px) {
  .contactus_talk_content {
    width: 100%;
    margin: 0 auto;
  }
  .contactus_talk_title h3 {
    font-size: 32px;
    font-weight: 600;
  }
  .contactus_talk_content_1,
  .contactus_talk_content_2,
  .contactus_talk_content_3 {
    width: 382px;
    height: 280px;
    padding: 10px 0px 10px 0px;
    margin-bottom: 30px;
  }

  .contactus_talk_content_1:hover,
  .contactus_talk_content_2:hover,
  .contactus_talk_content_3:hover {
    width: 396px;
    height: 300px;
  }

  .contactus_talk_content_1_title h6,
  .contactus_talk_content_2_title h6,
  .contactus_talk_content_3_title h6 {
    font-size: 20px;
    font-weight: 700;
  }

  .contactus_talk_content_1_title p {
    font-size: 16px;
  }
  .contactus_talk_content_2_title p {
    font-size: 12px;
  }

  .contactus_talk_content_3_title p {
    font-size: 16px;
  }

  .map_section {
    flex-direction: column;
  }

  .map_section_right iframe {
    width: 100%;
  }

  .contactus_talk_content_1_image,
  .contactus_talk_content_2_image,
  .contactus_talk_content_3_image {
    width: 60px;
    height: 60px;
  }

  .contactus_talk_content_1_image img {
    top: 29%;
    left: 25%;
    width: 30px;
    height: 30px;
  }

  .contactus_talk_content_2_image img {
    top: 26%;
    left: 25%;
    width: 30px;
    height: 30px;
  }

  .contactus_talk_content_3_image img {
    top: 30%;
    left: 25%;
    width: 30px;
    height: 30px;
  }
  .contactus_talk_content_2_title p {
    line-height: 1.8;
  }
}

@media (max-width: 768px) {
  .contactus_talk_content {
    width: 100%;
    margin: 0 auto;
  }
  .contactus_talk_title h3 {
    font-size: 28px;
    font-weight: 600;
  }

  .contactus_talk_content_1_title h6,
  .contactus_talk_content_2_title h6,
  .contactus_talk_content_3_title h6 {
    font-size: 20px;
    font-weight: 700;
  }

  .contactus_talk_content_1_title p {
    font-size: 13px;
  }
  .contactus_talk_content_2_title p {
    font-size: 11px;
    line-height: 1.5;
  }

  .contactus_talk_content_3_title p {
    font-size: 13px;
  }

  .map_section {
    flex-direction: column;
  }

  .map_section_right iframe {
    width: 100%;
  }
  .arrow_buttons {
    flex-direction: column;
  }

  .arrow_button {
    margin: 5px 0;
  }
}

@media (max-width: 695px) {
  .contactus_talk_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .contactus_talk_content_1,
  .contactus_talk_content_2,
  .contactus_talk_content_3 {
    width: 95%;
    height: auto;
  }

  .contactus_talk_content_1:hover,
  .contactus_talk_content_2:hover,
  .contactus_talk_content_3:hover {
    width: 95%;
    height: auto;
  }
  .contactus_talk_content_2_title p {
    line-height: 1.5;
  }
}

@media (max-width: 450px) {
  .contactus_talk_content_1,
  .contactus_talk_content_2,
  .contactus_talk_content_3 {
    width: 95%;
    height: 220px;
  }

  .contactus_talk_content_1:hover,
  .contactus_talk_content_2:hover,
  .contactus_talk_content_3:hover {
    width: 95%;
    height: 220px;
  }
  .contactus_talk_content_2_title p {
    line-height: 1.5;
  }
  .contactus_talk_content {
    margin-top: 5px;
  }

  .contactus_talk_content_1_image,
  .contactus_talk_content_2_image,
  .contactus_talk_content_3_image {
    width: 50px;
    height: 50px;
  }

  .contactus_talk_content_1_image img {
    top: 29%;
    left: 26%;
    width: 25px;
    height: 25px;
  }

  .contactus_talk_content_2_image img {
    top: 27%;
    left: 26%;
    width: 25px;
    height: 25px;
  }

  .contactus_talk_content_3_image img {
    top: 28%;
    left: 25%;
    width: 25px;
    height: 25px;
  }

  .contactus_talk_map_section .map_section_right iframe{
    height: 300px;
  }
}

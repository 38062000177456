.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10000;
}
/*  ==================================TOPBAR SECTION============================================*/

.topbar {
  /* max-width: 100%; */
  width: 100%;
  height: auto;
  gap: 0px;
  background-color: var(--TopBar-Dark-Green);
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.topbar-left {
  background-color: var(--TopBar-Light-Green);
  width: 408px;
  height: 40px;
  gap: 0px;
}
.topbar-left-social {
  display: flex;
  flex-direction: row;
  width: 188px;
  height: 24px;
  top: 8px;
  left: 140px;
  gap: 17px;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  vertical-align: middle;
}
.facebook_icon,
.twitter_icon,
.google_icon,
.instagram_icon {
  width: 24px;
  height: 24px;
  margin-top: 22px;
}
.youtube_icon,
.linkedIn_icon {
  width: 24px;
  height: 24px;
  margin-top: 26px;
}

.facebook_icon:hover,
.twitter_icon:hover,
.google_icon:hover,
.instagram_icon:hover {
  margin-top: 24px;
}
.youtube_icon:hover,
.linkedIn_icon:hover {
  margin-top: 28px;
}

.topbar-right {
  text-align: center;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 90px;
  gap: 50px;
  color: var(--white);
}

.topbar-right-call {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  gap: 5px;
}

.topbar-right-call img {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 19.89px;
  height: 19.89px;
}
.topbar-right-call span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.6px;
  cursor: pointer;
  list-style: none;
  color: var(--white);
  transition: color 0.5s ease;
}
.topbar-right-call span:hover {
  color: yellow;
}

.topbar-right-mail {
  display: flex;
  flex-direction: row;
  gap: 13px;
  float: right;
  align-items: center;
  text-decoration: none;
}
.topbar-right-mail img {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.topbar-right-mail span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.6px;
  cursor: pointer;
  color: var(--white);
  transition: color 0.5s ease;
}
.topbar-right-mail span:hover {
  color: yellow;
}

/* hover time mail icon  */

.topbar-right-mail .icon-wrapper {
  position: relative;
}

.topbar-right-mail .default-icon,
.topbar-right-mail .hover-icon {
  position: absolute;
  transition: opacity 0.5s ease;
  top: -12px;
  left: -18px;
}

.topbar-right-mail .default-icon {
  opacity: 1;
}

.topbar-right-mail .hover-icon {
  opacity: 0;
  font-size: 1.5rem;
  color: yellow;
}

.topbar-right-mail:hover .default-icon {
  opacity: 0;
}

.topbar-right-mail:hover .hover-icon {
  opacity: 1;
}

.topbar-right-mail:hover span {
  color: yellow;
  color: var(--TopBar-Light-Green);
}

/* hover time call icon  */

.topbar-right-call .icon-wrapper {
  position: relative;
}

.topbar-right-call .default-icon,
.topbar-right-call .hover-icon {
  position: absolute;
  transition: opacity 0.5s ease;
  top: -10px;
  left: -22px;
}

.topbar-right-call .default-icon {
  opacity: 1;
}

.topbar-right-call .hover-icon {
  opacity: 0;
  font-size: 1rem;
  color: yellow;
}

.topbar-right-call:hover .default-icon {
  opacity: 0;
}

.topbar-right-call:hover .hover-icon {
  opacity: 1;
  font-size: 0.5rem;
  width: 19px;
  height: 19px;
}

.topbar-right-call:hover span {
  color: yellow;
  color: var(--TopBar-Light-Green);
}
/* ===========================================NAVBAR SECTION=================================*/

.navbar_container {
  /* max-width: 1440px; */
  width: 100%;
  background-color: var(--white);
  margin: 0 auto;
  box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
}

.navbar {
  max-width: 100%;
  /* width: 1160px; */
  height: 75px;
  justify-content: center;
  /* margin: 0 10%; */
  margin: 0% 5% 0% 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  gap: 0;
  background-color: var(--white);
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-left {
  align-items: center;
}
.navbar-left img {
  width: 150px;
  height: 68px;
}
.navbar-right {
  width: 775px;
  height: 53px;
  gap: 40px;
  text-decoration: none;
  display: flex;
}

.navbar-right > ul {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  margin-top: 15px; /* add ? */
  list-style: none;
  text-decoration: none;
  gap: 20px;
}

.navbar-right > ul > li {
  list-style: none;
  text-decoration: none;
  cursor: pointer;
  padding: 0px 30px 49px 0px;
}

.navbar-right ul li a {
  text-decoration: none;
  color: var(--black);
}
.navbar-right ul.hamburgerOpen li.active a {
  color: green;
  transition: 0.5s ease;
  text-decoration: none;
  list-style: none;
}

.main-responsive-navbar-main .nav-item.active a {
  color: var(--TopBar-Light-Green);
  transition: 0.5s ease;
  text-decoration: none;
  list-style: none;
}

.home_section {
  position: relative;
}

.about_section {
  position: relative;
}

.services_section {
  position: relative;
}
.industries_section {
  position: relative;
}

.services_section::after {
  position: absolute;
  content: url(../../assets/icons/Vector.svg);
  top: -1px;
  left: 65px;
}
.contactus_btn {
  border: none;
  align-content: center;
  text-align: center;
  justify-content: center;
  border-radius: 6px;
  padding: 15px 30px 12px 30px;
  background-color: var(--TopBar-Light-Green);
  color: var(--white);
  font-weight: 700;
  font-size: 20px;
  line-height: 23.44px;
  cursor: pointer;
  position: relative;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.6s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.contactus_btn:hover {
  background-color: var(--TopBar-Dark-Green);
  color: var(--white);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

/* =============================NAVBAR SECTION ADD MEDIA QUERY============================ */

@media (max-width: 1200px) {

  .navbar {
    margin: 0 8%;
  }
}
@media (max-width: 1024px) {

  .navbar {
    margin: 0 5%;
  }
  .topbar {
    display: none;
  }
  .topbar-right {
    font-size: 14px;
    padding: 1px;
  }
  .navbar-left {
    padding-left: 25px;
  }
  .navbar-right {
    gap: 0px;
  }

  .navbar-right > ul {
    gap: 15px;
    margin-left: 100px;
  }

  .navbar-right > ul > li {
    padding: 0px 20px 49px 0px;
  }

  .contactus_btn {
    padding: 15px 17px 12px 17px;
    font-weight: 600;
    font-size: 14px;
    margin-left: 50px;
  }
}

@media (max-width: 930px) {
  .navbar-right > ul {
    margin-left: 50px;
  }
  .contactus_btn {
    margin-left: 18px;
  }

}


@media (max-width: 813px) {


  .contactus_btn {
    padding: 10px 12px 10px 12px;
    padding: 5px;
    font-weight: 500;
    font-size: 14px;
    margin-left: 50px;
  }
}
@media (max-width: 768px) {
  .nav-item.active::before {
    display: none;
  }
  .navbar {
    display: none;
  }
  .navbar-left {
    margin-left: 20px;
  }
  .navbar-right ul {
    background-color: blue;
    height: 100px;
    width: 100px;
    margin-top: 50px;
    position: absolute;
  }
}

/* ===================================RESPONSIVE NAVBAR============================================ */

.res nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
}

.res .logo {
  align-items: center;
  justify-content: center;
}
.res .logo img {
  height: 40px;
}

.res .nav {
  list-style: none;
  display: flex;
}

.res .nav li {
  position: relative;
}

.res .nav a {
  color: var(--white);
  text-decoration: none;
  padding: 10px 20px;
  display: block;
  border-bottom: 1px solid transparent;
}

.res .nav .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #34495e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.res .nav .dropdown li {
  width: 100%;
}

.res .nav .dropdown a {
  padding: 10px;
  border-bottom: none;
}

.res .nav .dropdown.show {
  display: block;
}

.res .menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.res .bar {
  height: 3px;
  width: 25px;
  background-color: rgb(255, 255, 255);
  margin: 4px 0;
  transition: all 0.3s;
}

.res .arrow {
  transition: transform 0.3s;
  margin-right: -200px;
}

.res .arrow.rotate {
  transform: rotate(180deg);
}
.nav-item.active::before {
  position: absolute;
  top: -0px;
  left: -25px;
  content: url(../../assets/icons/home_chess.svg);
  transition: 0.3s ease-in-out;
}

/* ==========================RESPONSIVE NAVBAR ADD MEDIA QUERY========================== */

@media (min-width: 769px) {
  .res nav {
    display: none;
  }
}

@media (max-width: 768px) {
  .res .menu-toggle {
    display: flex;
    justify-content: left;
  }
  .res nav {
    background-color: var(--white);
  }
  .res nav.nav-open {
    background-color: var(--TopBar-Dark-Green);
  }

  .main-responsive-navbar-main {
    width: 100%;
    height: 100%;
    position: sticky;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
  }

  .res .nav {
    display: none;
    flex-direction: column;
    width: 90%;
    height: 50%;
    background-color: var(--TopBar-Dark-Green);
    position: absolute;
    top: 76px;
    left: 0;
    gap: 5px;
    overflow-y: scroll;
    max-height: 90vh;
    z-index: 1000000;
  }

  .res nav .logo {
    align-items: center;
    margin-left: 15px;
  }
  .res nav .logo img {
    width: 180px;
    height: 82.07px;
  }

  .res .nav li {
    text-align: left;
  }

  .res .nav li a {
    font-size: 22px;
    font-weight: 400;
    margin-right: 20px;
    color: var(--white);
    line-height: 19.6px;
    margin-left: 15px;
    margin-top: 12px;
  }

  .res .nav.showing {
    width: 100%;
    height: 840px;
    max-width: 100%;
    display: flex;
    z-index: 100000;
    overflow-y: scroll;
  }

  .res .nav .dropdown {
    position: static;
    list-style: none;
    background-color: var(--TopBar-Dark-Green);
    height: auto;
  }

  .res .nav .dropdown li {
    width: 100%;
  }

  .res .nav .dropdown a {
    font-size: 20px;
    font-weight: 400;
    margin-right: 20px;
    color: var(--white);
    line-height: 19.6px;
    margin-top: 12px;
    margin-left: 1px;
  }
  .res .nav .dropdown a:hover {
    color: var(--white);
    transition: 0.3s;
    font-weight: 450;
  }

  .res .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .res .menu-item::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    float: right;
    font-size: 16px;
    margin-left: 51px;
  }
  .res .menu-item.active::before {
    content: "\f107";
  }
}

@media (max-width: 768px) {
  .res .nav.showing {
    display: flex;
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 600px) {
  .res .nav.showing {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Active state */
.nav-item.active a {
  color: green;
}

.nav-item.active::before {
  position: absolute;
  top: -8px;
  left: -25px;
  content: url(../../assets/icons/home_chess.svg);
  transition: 0.3s ease-in-out;
}
/* =======================================MEGAMENU CSS==================================================*/

/* .megamenu_main_container {
  width: 100%;
  margin: 0 auto;
  z-index: 1000;
  display: none;
  position: fixed;
  max-width: 1200px;
  padding: 20px;
  z-index: 2000;
  background-color: #d2e1f0;
  background-color: var(--white);
  margin-top: 30px;
  margin-left: -750px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.megamenu_main_layout {
  width: 100%;
  max-width: 1307px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}

.megamenu_layout_upper_section,
.megamenu_layout_lower_section {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}
.megamenu_layout_lower_section {
  margin-top: 10px;
}

.megamenu_layout_upper_section > div,
.megamenu_layout_lower_section > div {
  flex: 1;
  min-width: 300px;
  max-width: 377px;
}

.megamenu_layout_upper_section_1_title,
.megamenu_layout_upper_section_2_title,
.megamenu_layout_upper_section_3_title,
.megamenu_layout_lower_section_1_title,
.megamenu_layout_lower_section_2_title,
.megamenu_layout_lower_section_3_title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.megamenu_layout_upper_section_title_img img,
.megamenu_layout_lower_section_title_img img {
  width: 28px;
  height: 28px;
}

.megamenu_layout_upper_section p,
.megamenu_layout_lower_section p {
  font-size: 20px;
  font-weight: 600;
  color: var(--TopBar-Light-Green);
  margin: 3px 0;
  cursor: pointer;
}

.megamenu_layout_upper_section_1 li,
.megamenu_layout_upper_section_2 li,
.megamenu_layout_upper_section_3 li,
.megamenu_layout_lower_section_1 li,
.megamenu_layout_lower_section_2 li,
.megamenu_layout_lower_section_3 li {
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
  list-style: disc;
  color: var(--gray);
  text-align: left;
  margin: 0 0px -5px;
  padding: 1px;
  transition: 0.5s;
}

 

@media (max-width: 1024px) {
  .megamenu_main_layout {
    max-width: 100%;
  }

  .megamenu_layout_upper_section,
  .megamenu_layout_lower_section {
    gap: 16px;
  }

  .megamenu_layout_upper_section > div,
  .megamenu_layout_lower_section > div {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .megamenu_main_layout {
    flex-direction: column;
    align-items: left;
    text-align: left;
  }

  .megamenu_layout_upper_section,
  .megamenu_layout_lower_section {
    flex-direction: column;
    gap: 24px;
    margin-left: 25px;
  }

  .megamenu_layout_upper_section > div,
  .megamenu_layout_lower_section > div {
    max-width: 100%;
    text-align: center;
  }

  .megamenu_layout_upper_section_title,
  .megamenu_layout_lower_section_title {
    flex-direction: column;
    align-items: center;
  }

  .megamenu_layout_upper_section p,
  .megamenu_layout_lower_section p {
    font-size: 14px;
  }

  .megamenu_layout_upper_section ul,
  .megamenu_layout_lower_section ul {
    font-size: 14px;
  }

  .megamenu_layout_upper_section li,
  .megamenu_layout_lower_section li {
    line-height: 1.8;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .megamenu_layout_upper_section p,
  .megamenu_layout_lower_section p {
    font-size: 12px;
  }

  .megamenu_layout_upper_section li,
  .megamenu_layout_lower_section li {
    font-size: 12px;
  }
}

.navbar-right ul li:hover .megamenu_main_container {
  display: block;
} */

/* new upadated  */

/* .megamenu_layout_upper_section,
.megamenu_layout_lower_section {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}
.megamenu_layout_lower_section {
  margin-top: 10px;
}

.megamenu_layout_upper_section > div,
.megamenu_layout_lower_section > div {
  flex: 1;
  min-width: 300px;
  max-width: 377px;
} */

/* .megamenu_layout_upper_section_1_title,
.megamenu_layout_upper_section_2_title,
.megamenu_layout_upper_section_3_title,
.megamenu_layout_lower_section_1_title,
.megamenu_layout_lower_section_2_title,
.megamenu_layout_lower_section_3_title {
  display: flex;
  align-items: center;
  gap: 12px;
} */

/* .megamenu_layout_upper_section_title_img img,
.megamenu_layout_lower_section_title_img img {
  width: 28px;
  height: 28px;
} */

/* .megamenu_layout_upper_section p,
.megamenu_layout_lower_section p {
  font-size: 20px;
  font-weight: 600;
  color: var(--TopBar-Light-Green);
  margin: 3px 0;
  cursor: pointer;
}

.megamenu_layout_upper_section_1 li,
.megamenu_layout_upper_section_2 li,
.megamenu_layout_upper_section_3 li,
.megamenu_layout_lower_section_1 li,
.megamenu_layout_lower_section_2 li,
.megamenu_layout_lower_section_3 li {
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
  list-style: disc;
  color: var(--gray);
  text-align: left;
  margin: 0 0px -5px;
  padding: 1px;
  transition: 0.5s;
} */

/* ===========================MEGAMENU SECTION ADD MEDIA QUERY============================== */

@media (max-width: 1024px) {
  .megamenu_main_layout {
    max-width: 100%;
  }

  /* .megamenu_layout_upper_section,
  .megamenu_layout_lower_section {
    gap: 16px;
  } */

  /* .megamenu_layout_upper_section > div,
  .megamenu_layout_lower_section > div {
    max-width: 100%;
  } */
}

@media (max-width: 768px) {
  .megamenu_main_layout {
    flex-direction: column;
    align-items: left;
    text-align: left;
  }

  /* .megamenu_layout_upper_section,
  .megamenu_layout_lower_section {
    flex-direction: column;
    gap: 24px;
    margin-left: 25px;
  } */

  /* .megamenu_layout_upper_section > div,
  .megamenu_layout_lower_section > div {
    max-width: 100%;
    text-align: center;
  } */

  /* .megamenu_layout_upper_section_title,
  .megamenu_layout_lower_section_title {
    flex-direction: column;
    align-items: center;
  } */

  /* .megamenu_layout_upper_section p,
  .megamenu_layout_lower_section p {
    font-size: 14px;
  }

  .megamenu_layout_upper_section ul,
  .megamenu_layout_lower_section ul {
    font-size: 14px;
  }

  .megamenu_layout_upper_section li,
  .megamenu_layout_lower_section li {
    line-height: 1.8;
    text-align: left;
  } */
}

/* @media (max-width: 480px) {
  .megamenu_layout_upper_section p,
  .megamenu_layout_lower_section p {
    font-size: 12px;
  }

  .megamenu_layout_upper_section li,
  .megamenu_layout_lower_section li {
    font-size: 12px;
  }
} */

/* .navbar-right ul li:hover .megamenu_main_container {
  display: block;
} */

/* add cg css  */

.megamenu_main_container {
  width: 100%;
  max-width: 998px;
  height: auto;
  margin: 0 auto;
  position: fixed;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: none;
  justify-content: space-between;
  padding: 20px 0px 0px 20px;
  z-index: 2000;
  margin-top: 30px;
  margin-left: -650px;
  z-index: 2000;
}
.navbar-right ul li:hover .megamenu_main_container {
  display: block;
}

.megamenu_main_layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.megamenu_left_side {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.megamenu_left_side p {
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;
  text-align: left;
}

.megamenu_right_side {
  width: 70%;
  display: flex;
  gap: 35px;
}

.megamenu_right_side ul {
  list-style-type: disc;
  margin: 0 auto;
  margin-top: 15px;
}
.megamenu_right_side ul li a:hover {
  color: var(--TopBar-Dark-Green) !important;
}
.megamenu_right_side ul li a {
  font-size: 13px;
  margin-bottom: 10px;
  color: var(--black) !important;
  margin: 0 auto;
  line-height: 2.5;
  text-align: left;
  transition: 0.3s;
}

.megamenu_right_side li:hover{
  color: var(--TopBar-Dark-Green);
}

/* Hover effects */
.megamenu_left_side p:hover {
  color: var(--TopBar-Dark-Green);
}
.vertical_line {
  width: 0.5px;
  height: 350px;
  border: 1px solid var(--gray);
  margin-top: 15px;
  transform: scaleX(0.5);
  transform-origin: left;
}

.ourjourney_main_container {
  width: 100%;
  /* max-width: 1440px; */
  margin: 0 auto;
  /* height: 300px; */
  margin-top: 50px;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
}

.ourjourney_title p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  color: var(--TopBar-Light-Green);
  margin: 0 auto;
}

.ourjourney_title h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: var(--black);
  margin: 0 auto;
}

.ourjourney_timeline {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 50px;
  gap: 20px;
}

.ourjourney_timeline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--TopBar-Dark-Green);
  top: 50%;
  left: 0;
  z-index: 1;
}

.ourjourney_timeline_item_1,
.ourjourney_timeline_item_2,
.ourjourney_timeline_item_3,
.ourjourney_timeline_item_4,
.ourjourney_timeline_item_5,
.ourjourney_timeline_item_6 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  /* border: 1px solid red; */
}

.ourjourney_timeline_item_1 h6,
.ourjourney_timeline_item_3 h6,
.ourjourney_timeline_item_5 h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  color: var(--TopBar-Dark-Green);
  position: absolute;
  margin-top: 80px;
}

.ourjourney_timeline_item_3 h6,
.ourjourney_timeline_item_5 h6 {
  margin-top: 105px;
}

.ourjourney_timeline_item_2 h6,
.ourjourney_timeline_item_4 h6,
.ourjourney_timeline_item_6 h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  color: var(--TopBar-Dark-Green);
  position: absolute;
  margin-top: 35px;
}

.ourjourney_timeline_item_1_point img,
.ourjourney_timeline_item_3_point img,
.ourjourney_timeline_item_5_point img {
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 3;
  top: 117px;
  /* cursor: pointer; */
}

.ourjourney_timeline_item_3_point img,
.ourjourney_timeline_item_5_point img {
  top: 145px;
}

.ourjourney_timeline_item_2_point img,
.ourjourney_timeline_item_4_point img,
.ourjourney_timeline_item_6_point img {
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 3;
  /* cursor: pointer; */
}

.ourjourney_timeline_item_1_content,
.ourjourney_timeline_item_2_content,
.ourjourney_timeline_item_3_content,
.ourjourney_timeline_item_4_content,
.ourjourney_timeline_item_5_content,
.ourjourney_timeline_item_6_content {
  text-align: center;
}

.ourjourney_timeline_item_1_content_vertical_line img,
.ourjourney_timeline_item_3_content_vertical_line img,
.ourjourney_timeline_item_5_content_vertical_line img {
  display: block;
  margin: 0 auto;
  margin-top: 110px;
}

.ourjourney_timeline_item_3_content_vertical_line img,
.ourjourney_timeline_item_5_content_vertical_line img {
  margin-top: 135px;
}

.ourjourney_timeline_item_2_content_vertical_line img,
.ourjourney_timeline_item_4_content_vertical_line img,
.ourjourney_timeline_item_6_content_vertical_line img {
  display: block;
  margin: 0 auto;
}

.ourjourney_timeline_item_6_content_vertical_line img {
  margin-top: 37px;
}

.ourjourney_timeline_item_1_content p,
.ourjourney_timeline_item_3_content p,
.ourjourney_timeline_item_5_content p {
  margin-top: 5px;
  font-size: 16px;
  line-height: 1.5;
  width: 155px;
}

.ourjourney_timeline_item_3_content p,
.ourjourney_timeline_item_5_content p {
  margin-top: 10px;
}

.ourjourney_timeline_item_2_content p,
.ourjourney_timeline_item_4_content p,
.ourjourney_timeline_item_6_content p {
  margin-top: -110px;
  font-size: 16px;
  line-height: 1.5;
  width: 155px;
}

.popup-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content p {
  position: absolute;
  top: 38%;
  left: 4%;
  width: 25%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.popup-content2 p {
  position: absolute;
  bottom: -35%;
  left: 27%;
  width: 25%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.popup-content3 p {
  position: absolute;
  top: 38%;
  left: 49%;
  width: 25%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.popup-content4 p {
  position: absolute;
  bottom: -35%;
  left: 72%;
  width: 25%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/*===================================ADD MEDIA QUERY==================================== */

@media (max-width: 1024px) {
  .ourjourney_timeline_item_1_point img {
    top: 111px;
  }

  .ourjourney_timeline_item_3_point img,
  .ourjourney_timeline_item_5_point img {
    top: 140px;
  }
  .ourjourney_timeline_item_2_content p,
  .ourjourney_timeline_item_4_content p,
  .ourjourney_timeline_item_6_content p {
    margin-top: -130px;
    font-size: 16px;
    line-height: 1.5;
    width: inherit;
    width: 100px;
  }
  .ourjourney_timeline {
    gap: 0px;
  }

  .ourjourney_timeline_item_1_content_vertical_line img,
  .ourjourney_timeline_item_3_content_vertical_line img,
  .ourjourney_timeline_item_5_content_vertical_line img {
    display: block;
    margin: 0 auto;
    margin-top: 95px;
  }

  .ourjourney_timeline_item_3_content_vertical_line img,
  .ourjourney_timeline_item_5_content_vertical_line img {
    margin-top: 124px;
  }
}

@media (max-width: 940px) {
  .ourjourney_timeline_item_1_point img {
    top: 111px;
  }

  .ourjourney_timeline_item_3_point img,
  .ourjourney_timeline_item_5_point img {
    top: 140px;
  }
  .ourjourney_timeline_item_2_content p,
  .ourjourney_timeline_item_4_content p,
  .ourjourney_timeline_item_6_content p {
    margin-top: -130px;
    font-size: 16px;
    line-height: 1.5;
    width: inherit;
    width: 100px;
  }
  .ourjourney_timeline {
    gap: 0px;
  }
}

@media (max-width: 768px) {
  .ourjourney_timeline {
    flex-direction: column;
    justify-content: flex-start;
    gap: 55px;
  }

  .ourjourney_timeline_item_1,
  .ourjourney_timeline_item_3,
  .ourjourney_timeline_item_5 {
    align-items: flex-start;
    position: relative;
  }

  .ourjourney_timeline_item_2,
  .ourjourney_timeline_item_4,
  .ourjourney_timeline_item_6 {
    align-items: flex-end;
    position: relative;
  }
  .ourjourney_timeline_item_1 h6,
  .ourjourney_timeline_item_3 h6,
  .ourjourney_timeline_item_5 h6 {
    margin: 0;
    margin-top: 28px;
    margin-left: -25px;
  }
  .ourjourney_timeline_item_1 h6 {
    margin-top: 12px;
  }

  .ourjourney_timeline_item_2 h6,
  .ourjourney_timeline_item_4 h6,
  .ourjourney_timeline_item_6 h6 {
    margin: 0;
    margin-top: 15px;
    margin-right: -60px;
  }

  .ourjourney_timeline_item_1_content,
  .ourjourney_timeline_item_3_content,
  .ourjourney_timeline_item_5_content {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }

  .ourjourney_timeline_item_2_content,
  .ourjourney_timeline_item_4_content,
  .ourjourney_timeline_item_6_content {
    margin-right: 18px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .ourjourney_timeline::before {
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  .ourjourney_timeline_item_1_content_vertical_line img,
  .ourjourney_timeline_item_3_content_vertical_line img,
  .ourjourney_timeline_item_2_content_vertical_line img,
  .ourjourney_timeline_item_4_content_vertical_line img,
  .ourjourney_timeline_item_5_content_vertical_line img,
  .ourjourney_timeline_item_6_content_vertical_line img {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .ourjourney_timeline_item_3_content_vertical_line img,
  .ourjourney_timeline_item_5_content_vertical_line img {
    transform: rotate(270deg);
    margin: 0 10px;
    margin-left: 50px;
    margin-top: 14px;
  }

  .ourjourney_timeline_item_1_content_vertical_line img {
    transform: rotate(270deg);
    margin: 0 10px;
    margin-left: 46px;
    margin-top: -3px;
  }

  .ourjourney_timeline_item_2_content_vertical_line img,
  .ourjourney_timeline_item_4_content_vertical_line img,
  .ourjourney_timeline_item_6_content_vertical_line img {
    transform: rotate(270deg);
    margin: 0 10px;
    margin-left: -27px;
  }
  .ourjourney_timeline_item_1_content p,
  .ourjourney_timeline_item_3_content p,
  .ourjourney_timeline_item_5_content p {
    margin: 0;
    width: 180px;
    position: absolute;
    top: 35%;
    text-align: center;
    font-size: 14px;
    margin: 0;
    width: 150px;
    left: 110px;
  }

  .ourjourney_timeline_item_1_content p {
    top: 10%;
  }

  .ourjourney_timeline_item_2_content p,
  .ourjourney_timeline_item_4_content p,
  .ourjourney_timeline_item_6_content p {
    margin: 0;
    width: 180px;
    position: absolute;
    margin: 0;
    text-align: center;
    font-size: 14px;
    width: 150px;
    left: -200px;
  }

  .ourjourney_timeline_item_1_point img,
  .ourjourney_timeline_item_3_point img,
  .ourjourney_timeline_item_5_point img {
    position: absolute;
    z-index: 3;
    margin-top: -112px;
    margin-left: 30px;
  }

  .ourjourney_timeline_item_1_point img {
    margin-top: -100px;
  }

  .ourjourney_timeline_item_2_point img,
  .ourjourney_timeline_item_4_point img,
  .ourjourney_timeline_item_6_point img {
    position: absolute;
    z-index: 3;
    margin-top: -37px;
    margin-left: -22px;
  }

  .popup-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

  .popup-content p {
    position: absolute;
    top: 16%;
    left: 20%;
    width: 60%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .popup-content2 p {
    position: absolute;
    bottom: 0%;
    left: 20%;
    width: 60%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .popup-content3 p {
    position: absolute;
    top: 137%;
    left: 20%;
    width: 60%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .popup-content4 p {
    position: absolute;
    bottom: -140%;
    left: 15%;
    width: 70%;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}
@media (max-width: 425px) {
  .ourjourney_main_container {
    margin-top: 40px;
  }
  .popup-content p {
    position: absolute;
    top: 18%;
    left: 7%;
    width: 90%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .popup-content2 p {
    position: absolute;
    bottom: 0%;
    left: 7%;
    width: 90%;
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .popup-content3 p {
    position: absolute;
    top: 137%;
    left: 7%;
    width: 90%;
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .popup-content4 p {
    position: absolute;
    bottom: -140%;
    left: 7%;
    width: 90%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .ourjourney_timeline_item_1_content p,
  .ourjourney_timeline_item_3_content p,
  .ourjourney_timeline_item_5_content p {
    margin: 0;
    width: 180px;
    position: absolute;
    top: 20%;
    text-align: center;
    font-size: 12px;
    margin: 0;
    width: 115px;
    left: 110px;
  }
  .ourjourney_timeline_item_2_content p,
  .ourjourney_timeline_item_4_content p,
  .ourjourney_timeline_item_6_content p {
    margin: 0;
    width: 180px;
    position: absolute;
    margin: 0;
    text-align: center;
    font-size: 12px;
    width: 105px;
    left: -162px;
  }
}

@media (max-width: 375px) {
  .ourjourney_timeline_item_1_content_vertical_line img,
  .ourjourney_timeline_item_3_content_vertical_line img,
  .ourjourney_timeline_item_5_content_vertical_line img {
    transform: rotate(270deg);
    margin: 0 10px;
    margin-left: 30px;
    margin-top: 32px;
    width: 5px;
  }

  .ourjourney_timeline_item_1_content_vertical_line img {
    transform: rotate(270deg);
    margin: 0 10px;
    margin-left: 30px;
    margin-top: 3px;
    width: 5px;
  }

  .ourjourney_timeline_item_2_content_vertical_line img,
  .ourjourney_timeline_item_4_content_vertical_line img,
  .ourjourney_timeline_item_6_content_vertical_line img {
    transform: rotate(270deg);
    margin: 0 10px;
    margin-left: -16px;
    margin-top: 20px;
    width: 5px;
  }

  .ourjourney_timeline_item_1_content p,
  .ourjourney_timeline_item_3_content p,
  .ourjourney_timeline_item_5_content p {
    margin: 0;
    width: 180px;
    position: absolute;
    top: 20%;
    text-align: center;
    font-size: 12px;
    margin: 0;
    width: 85px;
    left: 75px;
  }
  .ourjourney_timeline_item_2_content p,
  .ourjourney_timeline_item_4_content p,
  .ourjourney_timeline_item_6_content p {
    margin: 0;
    width: 180px;
    position: absolute;
    margin: 0;
    text-align: center;
    font-size: 12px;
    width: 85px;
    left: -125px;
    top: 15px;
  }
  .ourjourney_timeline_item_1_point img,
  .ourjourney_timeline_item_3_point img,
  .ourjourney_timeline_item_5_point img {
    position: absolute;
    z-index: 3;
    margin-top: -102px;
    margin-left: 20px;
  }

  .ourjourney_timeline_item_2_point img,
  .ourjourney_timeline_item_4_point img,
  .ourjourney_timeline_item_6_point img {
    position: absolute;
    z-index: 3;
    margin-top: -33px;
    margin-left: -22px;
  }

  .ourjourney_timeline_item_1 h6,
  .ourjourney_timeline_item_3 h6,
  .ourjourney_timeline_item_5 h6 {
    margin: 0;
    margin-top: 40px;
    margin-left: -35px;
  }

  .ourjourney_timeline_item_1 h6{
    margin: 0;
    margin-top: 11px;
    margin-left: -35px;
  }

  .ourjourney_timeline_item_2 h6,
  .ourjourney_timeline_item_4 h6,
  .ourjourney_timeline_item_6 h6 {
    margin: 0;
    margin-top: 25px;
    margin-right: -55px;
  }
}

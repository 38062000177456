.about_director_main_container {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  position: relative;
}
/* .about_director_main_container::after {
  position: absolute;
  content: "";
  width: 200px;
  height: 180px;
  background: url(../../../assets/icons/choose_us_section_round.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  right: -7.5%;
  top: -13%;
  z-index: -1;
} */
.about_director_left_side_section {
  animation: moveUpLeft 3s ease-out;
}

@keyframes moveUpLeft {
  from {
    transform: translate(0, 0);
    opacity: 0;
  }
  to {
    transform: translate(-20px, -20px);
    opacity: 1;
  }
}

.about_director_left_side_section img {
  width: 475px;
  height: 375px;
}

.about_director_right_side_section_title h3 {
  color: var(--black);
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;
  margin: 0 auto;
  margin-left: 30px;
}

.about_director_right_side_section_title p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  text-align: left;
  color: var(--TopBar-Light-Green);
  margin-left: 30px;
}

.about_director_right_side_section_description ul {
  padding-right: 15px;
  margin-left: 20px;
}

.about_director_right_side_section_description ul li {
  color: var(--gray);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: justify;
}

/*================================ADD MEDIA QUERY==================================== */
@media (max-width: 1250px) {
  .about_director_main_container::after {
    display: none;
  }
}
@media (max-width: 1024px) {
  .about_director_main_container {
    align-items: inherit;
  }

  .about_director_main_container {
    flex-direction: row;
  }

  .about_director_left_side_section img {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .about_director_right_side_section_title h3 {
    font-size: 26px;
    line-height: 30px;
  }

  .about_director_right_side_section_title p {
    font-size: 14px;
  }
  .about_director_right_side_section_description ul {
    padding: 0px 0px 0px 15px;
    margin: 0;
  }

  .about_director_right_side_section_description ul li {
    font-size: 12px;
    padding-right: 15px;
  }
}

@media (max-width: 768px) {
  .about_director_main_container {
    margin: 0 auto;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: inherit;
  }

  .about_director_left_side_section img {
    width: 100%;
    height: auto;
  }

  .about_director_right_side_section {
    margin-left: 0;
    margin-top: 20px;
  }

  .about_director_right_side_section_title h3 {
    font-size: 28px;
    line-height: 32px;
  }

  .about_director_right_side_section_title p {
    font-size: 12px;
  }

  .about_director_right_side_section_description ul li {
    font-size: 12px;
    line-height: 22px;
  }
}
@media (max-width: 615px) {
  .about_director_main_container {
    margin-top: 0px;
    margin-bottom: 10px;
    flex-direction: column;
    padding: 35px;
  }
}

@media (max-width: 425px) {
  .about_director_main_container {
    margin-top: 0px;
    margin-bottom: 10px;
    flex-direction: column;
    padding: inherit;
  }
  .about_director_right_side_section_description ul {
    padding: 0px 5px 0px 30px;
  }

  .about_director_right_side_section_description ul li {
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

.contactus_page_main_container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 50px;
  padding: 0 15px;
  position: relative;
}

.contactus_page_main_container::before {
  content: "";
  background: url(../../../assets/icons/about_section_ovreview_dots.svg);
  width: 300px;
  height: 350px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  top: 8%;
  left: 0%; 
  position: absolute;
}

.contactus_page_main_container::after {
  content: "";
  background: url(../../../assets/icons/contactus_bg_image.svg);
  width: 18%;
  height: 1000px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  top: -8%;
  left: 82%;
  content: "";
  position: absolute;
  z-index: -99999;
}

.contactus_page_title_main_container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  padding: 0 15px;
  gap: 30px;
  position: relative;
}

.contactus_title p {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--black);
}

.contactus_selected_section {
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 auto;
}

.contactus_callus_selected_section,
.contactus_carrer_selected_section {
  width: 100%;
  max-width: 400px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
  padding: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 15px;
  justify-content: space-evenly;
  background-color: var(--white);
}

.contactus_callus_icon,
.contactus_carrer_icon {
  width: 60px;
  height: 60px;
  background-color: var(--TopBar-Dark-Green);
  border-radius: 15px;
  position: relative;
  margin-top: 8px;
}

.contactus_callus_icon img,
.contactus_carrer_icon img {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 25%;
  left: 25%;
}

.contactus_callus_selected_section p,
.contactus_carrer_selected_section p {
  font-size: 22px;
  font-weight: 600;
  color: var(--black);
}

.contactus_carrer_selected_section p {
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  font-weight: 800;
}

.contactus_form_main_container {
  width: 100%;
  max-width: 960px;
  padding: 15px;
  margin: 0 auto;
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
  border-radius: 15px;
  background-color: var(--white);
}

.contactus_form {
  width: 100%;
}

.contactus_form_part_1,
.contactus_form_part_2 {
  display: flex;
  gap: 15px;
  margin-top: 25px;
  justify-content: space-between;
}

.contactus_form_part_1_left,
.contactus_form_part_1_right,
.contactus_form_part_2_left,
.contactus_form_part_2_right,
.contactus_form_part_2_right_document {
  width: 100%;
  max-width: 480px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.contactus_form_part_1_left label,
.contactus_form_part_1_right label,
.contactus_form_part_2_left label,
.contactus_form_part_2_right label,
.contactus_form_part_2_right_document label {
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
}

.contactus_form_part_1_left input,
.contactus_form_part_1_right input,
.contactus_form_part_2_left input,
.contactus_form_part_2_right input,
.contactus_form_part_2_right_document input {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: 1px solid var(--border);
  margin-top: 15px;
  font-size: 14.4px;
  color: var(--gray);
  padding: 0 15px;
}

.upload_icon_container {
  width: 100%;
  max-width: 480px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid var(--border);
  margin-top: 15px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.upload_icon_container img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
  cursor: pointer;
}

.file-preview {
  display: flex;
  align-items: center;
  font-size: 14.4px;
  color: var(--gray);
}

.file-preview img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.contactus_form_part_3 {
  margin-top: 25px;
}

.contactus_form_part_3 label {
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
}

.contactus_form_part_3 textarea {
  width: 100%;
  height: 200px;
  border: 1px solid var(--border);
  border-radius: 15px;
  margin-top: 10px;
  font-size: 14.4px;
  padding: 15px;
}

.contactus_form_button button {
  width: 100%;
  max-width: 240px;
  height: 60px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  color: var(--white);
  background-color: var(--TopBar-Light-Green);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.6s ease;
  margin-top: 30px;
  margin-left: auto;
  border: none;
}

.contactus_form_button button:hover {
  border: 1px solid var(--TopBar-Light-Green);
  color: var(--white);
  background-color: var(--TopBar-Dark-Green);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.contactus_callus_selected_section.active,
.contactus_carrer_selected_section.active {
  border: 3px solid var(--TopBar-Dark-Green);
}

.file-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete_btn {
  margin-left: auto;
}
/*=========================================== ADD MEDIA QUERY  ==================================*/
@media (max-width: 1350px) {
  .contactus_page_main_container::after {
    display: none;
  }
  .contactus_page_main_container::before {
    display: none;

  }
}
@media (max-width: 1024px) {
  .contactus_title p {
    font-size: 30px;
  }

  .contactus_selected_section {
    flex-direction: row;
    align-items: center;
  }

  .contactus_selected_section {
    width: 85%;
    gap: 25px;
  }

  .contactus_callus_selected_section,
  .contactus_carrer_selected_section {
    width: 90%;
    max-width: 100%;
    padding: 10px;
  }

  .contactus_form_button button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .contactus_title p {
    font-size: 24px;
  }

  .contactus_callus_selected_section,
  .contactus_carrer_selected_section {
    padding: 10px;
    flex-direction: column;
    text-align: center;
  }

  .contactus_callus_icon,
  .contactus_carrer_icon {
    width: 60px;
    height: 60px;
  }

  .contactus_callus_icon img,
  .contactus_carrer_icon img {
    width: 30px;
    height: 30px;
  }

  .contactus_form_part_1,
  .contactus_form_part_2 {
    flex-direction: row;
    gap: 0px;
  }

  .contactus_form_part_1_left input,
  .contactus_form_part_1_right input,
  .contactus_form_part_2_left input,
  .contactus_form_part_2_right input,
  .contactus_form_part_2_right_document input {
    width: 90%;
  }

  .contactus_form_part_3 input {
    height: 200px;
    width: 90%;
  }
  .contactus_form_part_3 textarea {
    width: 95%;
  }

  .contactus_form_button button {
    width: 100%;
    max-width: 220px;
    height: 55px;
    font-size: 20px;
    margin-top: 20px;
    margin-left: auto;
    border: none;
  }

  .contactus_selected_section {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 0px;
    gap: 0px;
  }

  .contactus_callus_selected_section,
  .contactus_carrer_selected_section {
    width: 45%;
    text-align: center;
  }

  .contactus_callus_selected_section p,
  .contactus_carrer_selected_section p {
    font-size: 16px;
  }
  .contactus_callus_icon,
  .contactus_carrer_icon {
    margin: 0 auto;
    padding: 0px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .contactus_callus_selected_section,
  .contactus_carrer_selected_section {
    flex-direction: column;
    padding: 10px 15px;
    width: 65%;
  }
  .contactus_title p {
    font-size: 24px;
  }

  .contactus_callus_icon,
  .contactus_carrer_icon {
    width: 45px;
    height: 45px;
  }

  .contactus_callus_icon img,
  .contactus_carrer_icon img {
    width: 20px;
    height: 20px;
  }

  .contactus_form_part_3 input {
    height: 150px;
  }

  .contactus_title p {
    font-size: 22px;
  }

  .contactus_form_part_1,
  .contactus_form_part_2 {
    flex-direction: column;
    gap: 15px;
  }

  .contactus_form_part_1_left input,
  .contactus_form_part_1_right input,
  .contactus_form_part_2_left input,
  .contactus_form_part_2_right input,
  .contactus_form_part_2_right_document input {
    width: 100%;
    height: 50px;
  }

  .contactus_form_part_3 textarea {
    width: 100%;
  }

  .contactus_form_button button {
    width: 100%;
    max-width: 180px;
    height: 45px;
    font-size: 18px;
  }

  .contactus_selected_section {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 0px;
    gap: 10px;
  }

  .contactus_callus_selected_section p,
  .contactus_carrer_selected_section p {
    font-size: 13.5px;
  }
}
@media (max-width: 320px) {
  .contactus_callus_selected_section p,
  .contactus_carrer_selected_section p {
    font-size: 12px;
  }
  .contactus_title p {
    font-size: 18px;
  }
}

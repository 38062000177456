* {
  box-sizing: border-box;
}



.hero-section-main-container {
  /* max-width: 100%; */
  width: 100%;
  height: 489px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.hero-section-content-image {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* width: 1440px; */
  width: 100%;
  height: 479px;
  background-color: var(--black);
  background-image: url(../../../assets/images/hero_section_image.jpg);
  background-size: cover;
  transform: scaleX(-1);
  z-index: -1;
  transition: transform 1s, background-image 1s;
  clip-path: polygon(
    50% 0%,
    0% 0%,
    0% 88.59%,
    30.42% 92.19%,
    60.83% 95.79%,
    87.13% 98.53%,
    100% 100%,
    100% 63.95%,
    100% 0%
  );
}

.hero-section-content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  float: left;
  left: 100px;
  justify-content: center;
}

.hero-section-leftside {
  color: var(--white);
}

.hero-section-leftside-eyebrow-heading {
  display: flex;
}

.hero-section-leftside-eyebrow-heading-text {
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  margin-left: 50px;
  margin-top: 3px;
  text-transform: uppercase;
}

.hero-section-leftside-eyebrow-heading::before {
  content: url("../../../assets/icons/hero_section_line.svg");
}

.hero-section-leftside-h2 {
  font-size: 32px;
  font-weight: 700;
}

.hero-section-leftside-p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  line-height: 1.5;
  width: 48%;
}

.hero-section-leftside-btn {
  margin-top: 18px;
  border: none;
  text-align: center;
  border-radius: 6px;
  padding: 15px 27px;
  background-color: var(--TopBar-Light-Green);
  color: var(--white);
  font-weight: 700;
  font-size: 19px;
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block;
  position: relative;
  transition: 0.6s;
}
.hero-section-leftside-btn:hover {
  background-color: var(--TopBar-Dark-Green);
  color: var(--white);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.hero-section-navigationbar {
  display: flex;
  width: 100px;
  height: 28px;
  gap: 8px;
  position: absolute;
  top: 82%;
  right: 45%;
  cursor: pointer;
}

.rectangle_icon {
  transition: transform 0.5s ease-in-out;
}

.rectangle_icon_1 {
  width: 21px;
  height: 5px;
}

.rectangle_icon_2,
.rectangle_icon_3,
.rectangle_icon_4,
.rectangle_icon_5,
.rectangle_icon_6 {
  width: 8px;
  height: 8px;
}

.last_image {
  position: sticky;
  background-image: url(../../../assets/icons/hero_section_vector.svg);
  width: 915px;
  height: 64px;
  /* margin-top: 421px; */
  /* margin-left: 525px; */
  top:89%;
  left: 100%;
  background-repeat: no-repeat;
}


/* Add Media Query */

@media (max-width: 1220px) {
  .last_image {
    position: absolute;
    background-image: url(../../../assets/icons/hero_section_vector.svg);
    width: 915px;
    height: 64px;
    margin-top: 417px;
    margin-left: 525px;
  }
}
@media (max-width: 1120px) {
  .last_image {
    position: absolute;
    background-image: url(../../../assets/icons/hero_section_vector.svg);
    width: 915px;
    height: 64px;
    margin-top: 411px;
    margin-left: 525px;
  }
}
@media (max-width: 1050px) {
  .last_image {
    position: absolute;
    background-image: url(../../../assets/icons/hero_section_vector.svg);
    width: 915px;
    height: 64px;
    margin-top: 410px;
    margin-left: 525px;
  }
}

@media (max-width: 1024px) {
  .last_image {
    display: none;
  }

  .hero-section-main-container::before {
    background-image: url(../../../assets/images/hero_section_image.jpg);
  }
}
@media (max-width: 768px) {
  .hero-section-content {
    float: left;
    margin-left: -30px;
    position: relative;
  }

  .hero-section-leftside {
    color: var(--white);
  }

  .hero-section-leftside-eyebrow-heading {
    display: flex;
  }

  .hero-section-leftside-eyebrow-heading-text {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .hero-section-leftside-h2 {
    font-size: 32px;
    font-weight: 700;
  }

  .hero-section-leftside-p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    margin-top: -10px;
    width: 55%;
    line-height: 1.5;
  }

  .hero-section-leftside-btn {
    margin-top: 18px;
    border: none;
    align-content: center;
    text-align: center;
    justify-content: center;

    border-radius: 6px;
    padding: 15px 30px;
    background-color: var(--TopBar-Light-Green);
    color: var(--white);
    font-weight: 700;
    font-size: 19px;
    /* cursor: pointer; */
  }
}
@media (max-width: 590px) {
  .hero-section-leftside-eyebrow-heading::before {
    display: none;
  }
  .hero-section-leftside {
    margin-left: -50px;
  }
  .hero-section-leftside-eyebrow-heading-text {
    position: absolute;
    font-size: 10px;
    font-weight: 400;
    line-height: 22.4px;

    text-transform: uppercase;
    margin-left: -1px;
  }

  .hero-section-leftside-h2 {
    font-size: 32px;
    font-weight: 700;
    margin-top: 50px;
  }

  .hero-section-leftside-p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    margin-top: 10px;
    width: 70%;
    line-height: 1.5;
  }
  .hero-section-leftside-btn {
    margin-top: 10px;
  }
}
@media (max-width: 375px) {
  .hero-section-leftside {
    margin-left: -50px;
    margin-top: -50px;
  }
  .hero-section-leftside-eyebrow-heading-text {
    position: absolute;
    font-size: 8px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    width: 100%;
  }

  .hero-section-leftside-h2 {
    font-size: 20px;
    font-weight: 700;
  }

  .hero-section-leftside-p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    line-height: 1.5;
    width: 70%;
  }
  .hero-section-leftside-btn {
    margin-top: 10px;
  }
}

.about_experience_main_container {
  /* max-width: 1440px; */
  width: 100%;
  height: auto;
  background-image: url(../../../assets/icons/about_experience_direct_bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 1rem;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about_experience_content {
  max-width: 1160px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0px 20px 10px 30px;
  position: relative;
}

.about_experience_content_left_side {
  width: 55%;
  margin-top: -20px;
}
.about_experience_content_right_side {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 26px;
}

.about_experience_content_left_side::before {
  content: "";
  background: url(../../../assets/icons/about_experience_leftside.svg) no-repeat;
  position: absolute;
  width: 75px;
  height: 280px;
  top: 10%;
  left: -7.5%;
  background-size: contain;
  z-index: 0.5;
}

.about_experience_content_right_side::after {
  content: "";
  background: url(../../../assets/icons/about_experience_rightside.svg)
    no-repeat;
  position: absolute;
  width: 75px;
  height: 280px;
  top: 0%;
  right: -7.7%;
  z-index: 0.5;
  background-size: cover;
}

.about_experience_content_left_side_text {
  text-align: left;
}

.about_experience_content_left_side_text h6 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: var(--white);
  width: 80%;
}

.about_experience_content_left_side_text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--white);
  margin-top: -50px;
  width: 70%;
  text-align: justify;
}

.about_experience_content_right_side_1,
.about_experience_content_right_side_2,
.about_experience_content_right_side_3 {
  text-align: center;
  margin-top: 30px;
}

.about_experience_content_right_side_1 p,
.about_experience_content_right_side_2 p,
.about_experience_content_right_side_3 p {
  font-size: 20.5px;
  font-weight: 300;
  line-height: 32.5px;
}

.about_experience_content_right_side_1_round,
.about_experience_content_right_side_2_round,
.about_experience_content_right_side_3_round {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.about_experience_content_right_side_1_round span,
.about_experience_content_right_side_2_round span,
.about_experience_content_right_side_3_round span {
  font-size: 35px;
  font-weight: 700;
  line-height: 46.4px;
}

/*================================ADD MEDIA QUERY==================================== */

@media (max-width: 1325px) {
  .about_experience_content_right_side::after,
  .about_experience_content_left_side::before {
    display: none;
  }
}



@media (max-width: 1150px) {
  .about_experience_content {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }

  .about_experience_content_left_side {
    width: 100%;
    margin-top: 0;
  }

  .about_experience_content_left_side_text {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    padding: 0px;
  }
  .about_experience_content_left_side_text h6 {
    font-size: 32px;
  }

  .about_experience_content_right_side {
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 0px;
  }

  .about_experience_content_right_side_1,
  .about_experience_content_right_side_2,
  .about_experience_content_right_side_3 {
    width: 80%;
    margin: 10px 0;
  }
  .about_experience_content_right_side_1 p,
  .about_experience_content_right_side_2 p,
  .about_experience_content_right_side_3 p {
    margin: 0 auto;
  }

  .about_experience_content_right_side_1_round,
  .about_experience_content_right_side_2_round,
  .about_experience_content_right_side_3_round {
    width: 100px;
    height: 100px;
  }

  .about_experience_content_right_side_1_round span,
  .about_experience_content_right_side_2_round span,
  .about_experience_content_right_side_3_round span {
    font-size: 1.5rem;
  }

  .about_experience_content_left_side_text h6 {
    width: auto;
  }
  .about_experience_content_left_side_text p {
    width: auto;
    padding: 0px 10px;
    text-align: justify;
  }
}
@media (max-width: 425px) {
  .about_experience_content_left_side_text h6 {
    font-size: 26px;
  }
  .about_experience_content_right_side {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
  .about_experience_content_left_side_text p {
    font-size: 14px;
  }
  .about_experience_main_container {
    margin-top: -25px;
  }
  .about_experience_content_left_side {
    margin-top: -35px;
  }

  .about_experience_content_left_side_text h6 {
    font-size: 24px;
  }

  .about_experience_content_left_side_text p {
    font-size: 14px;
    text-align: justify;
    padding: 0px 10px;
  }
}
@media (max-width: 320px) {
  .about_experience_content_left_side_text h6 {
    font-size: 24px;
  }
  .about_experience_content_left_side_text p {
    font-size: 12px;
  }
  .about_experience_main_container {
    margin-top: -25px;
  }
  .about_experience_content_left_side {
    margin-top: -35px;
  }

  .about_experience_content_left_side_text h6 {
    font-size: 22px;
  }

  .about_experience_content_left_side_text p {
    font-size: 14px;
    text-align: justify;
    padding: 0px 10px;
  }
}

.video_section_main_container {
  /* max-width: 1440px; */
  width: 100%;
  height: auto;
  min-height: 550px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, #357960 92.26%),
    url(.././../../assets/images/video_section_bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  position: relative;
}

.video_section_left_side {
  max-width: 90%;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  gap: 32px;
  margin-top: -100px;
}

.video_section_left_side_content {
  text-align: left;
  width: 44%;
  margin-left: 0;
  padding: 20px;
  gap: 16px;
}

.video_section_left_side_content h6 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: var(--white);
  margin: 10px 0px;
}

.video_section_left_side_content h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: var(--white);
  margin: 0;
}

.video_section_left_side_content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--white);
  margin-top: 10px;
}

.video_section_left_side button {
  border: none;
  width: 159px;
  height: 53px;
  max-width: 200px;
  padding: 15px 25px 15px 25px;
  border-radius: 6px;
  background-color: var(--white);
  font-weight: 700;
  font-size: 20px;
  color: var(--TopBar-Dark-Green);
  cursor: pointer;
  margin-top: 5px;
  line-height: 23.44px;
  gap: 10px;
  position: relative;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
}

.video_section_left_side button:hover {
  background-color: var(--TopBar-Dark-Green);
  color: var(--white);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.video_section_left_side button::after {
  content: "";
  background: var(--TopBar-Dark-Green);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -30px;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.video_section_left_side button:active::after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.video_section_right_side {
  width: 106px;
  height: 106px;
  background-color: hsla(85, 72%, 40%, 0.5);
  position: absolute;
  left: 65%;
  display: flex;
  cursor: pointer;
  border-radius: 100%;
  overflow: hidden;
  transition: background-color 0.3s; 
}

.video_section_right_side:hover {
  background-color: hsla(85, 72%, 40%, 0.5);
  animation: pulse 1s infinite; 
}

.video_section_right_side_82 {
  width: 82px;
  height: 82px;
  background-color: hsla(85, 72%, 40%, 0.5);
  border-radius: 100%;
  margin: auto;
  display: flex;
}

.video_section_right_side_bg_color {
  width: 60px;
  height: 60px;
  background-color: var(--white);
  border-radius: 100%;
  margin: auto;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}

.video_section_right_side_bg_color img {
  max-width: 100%; 
  max-height: 70%;
  position: relative;
  z-index: 1; 
}


.video_section_right_side_bg_color::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: hsla(85, 72%, 40%, 0.5); 
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: none; 
  pointer-events: none; 
}

.video_section_right_side_bg_color:hover::before {
  animation: wave 2s linear infinite; 
}

@keyframes wave {
  0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1; 
  }
  100% {
      transform: translate(-50%, -50%) scale(4); 
      opacity: 0; 
  }
}


/* ===================================== ADD MEDIA QUERY =====================================  */

@media (max-width: 768px) {
  .video_section_main_container {
    padding: 0px;
  }
  .video_section_left_side {
    padding: 0px;
    margin-top: 0px;
    margin-top: -45px;
  }

  .video_section_left_side_content {
    padding: 0px;
    width: 65%;
  }
  .video_section_left_side_content h3 {
    font-size: 1.5em;
  }

  .video_section_left_side_content p,
  .video_section_left_side_content h6 {
    font-size: 0.875em;
    margin: inherit;
  }

  .video_section_left_side button {
    max-width: 150px;
    font-size: 1em;
  }

  .video_section_right_side {
    width: 80px;
    height: 80px;
    left: 65%;
    border-radius: 100%;
  }

  .video_section_right_side_82 {
    width: 60px;
    height: 60px;
  }

  .video_section_right_side_bg_color {
    width: 40px;
    height: 40px;
    /* top: 50%; */
  }
  .video_section_right_side_bg_color img {
    /* left: 40%; */
    top: 36%;
    max-width: 70%;
  }
  .video_section_left_side button {
    border: none;
    width: 150px;
    height: 50px;
    max-width: 200px;
    padding: 12px 22px 12px 22px;
    border-radius: 6px;
    background-color: var(--white);
    font-weight: 700;
    font-size: 18px;
    color: var(--TopBar-Dark-Green);
    cursor: pointer;
    margin-top: 20px;
    line-height: 23.44px;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .video_section_left_side_content h3 {
    font-size: 1.25em;
    line-height: 30px;
  }

  .video_section_left_side {
    padding: 0px;
    margin-top: 0px;
    margin-top: -55px;
  }

  .video_section_left_side_content {
    padding: 0px;
    width: 66%;
  }

  .video_section_left_side_content p,
  .video_section_left_side_content h6 {
    font-size: 0.75em;
    line-height: 32px;
  }

  .video_section_left_side button {
    border: none;
    width: 140px;
    height: 45px;
    max-width: 200px;
    padding: 10px 20px 10px 20px;
    border-radius: 6px;
    background-color: var(--white);
    font-weight: 600;
    font-size: 16px;
    color: var(--TopBar-Dark-Green);
    cursor: pointer;
    margin-top: 20px;
    line-height: 23.44px;
    gap: 10px;
  }
}

.video_section_right_side_bg_color button {
  border: none;
  background: none;
  cursor: pointer;
}

/* =========================================DIALOG BOX CSS================================ */

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.dialog-box {
  position: relative;
  width: 80%;
  max-width: 800px;
  height: 80%;
  max-height: 450px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 100px;
}

.dialog-close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  background: transparent;
  border: none;
  font-size: 22px;
  font-weight: 1000;
  cursor: pointer;
  color: var(--gray);
}

.dialog-content {
  width: 100%;
  height: 100%;
}
/* =============================DIALOG BOX CSS ADD MEDIA QUERY=========================== */

@media (max-width: 768px) {
  .dialog-box {
    width: 90%;
    height: 70%;
  }
}

@media (max-width: 480px) {
  .dialog-box {
    width: 95%;
    height: 60%;
  }
}


/* 
.animateColors {
  animation: colorChange 3s forwards;
}

@keyframes colorChange {
  0% {
    background-color: #FF5733 ;
  }
  25% {
    background-color: hsla(200, 100%, 50%, 0.5);
  }
  50% {
    background-color: hsla(335, 64%, 65%, 0.5);
  }
  75% {
    background-color: hsla(56, 54%, 50%, 0.5);
  }
  100% {
    background-color: hsla(197, 77%, 68%, 0.5);
  }
}


.animateColors.complete {
  animation: revertColor 1s forwards;
}

@keyframes revertColor {
  0% {
    background-color: var(--white);
  }
  100% {
    background-color: hsla(84, 59%, 77%, 0.5);
  }
}
 

.animateColors.complete {
  animation: revertColor 1s forwards;
} */

.hamburger {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
  float: left;
  margin-top: 4px;
  cursor: pointer;
}
.burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: var(--black);
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.hamburger {
  display: none;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
    z-index: 10;
  }
}
@media (max-width: 555px) {
  .hamburger {
    margin-right: 20px;
    margin-top: 4px;
  }
}
@media (max-width: 485px) {
  .hamburger {
    margin-right: 10px;
    margin-top: 4px;
  }
}

/* add last css  */

.close-icon {
  width: 2rem;
  height: 2rem;
}

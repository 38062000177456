.company_overview_main_container {
  max-width: 1440px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  gap: 36px;
  margin-bottom: 50px;
  position: relative;
}

.company_overview_left_section {
  width: 550px;
  height: 100%;
  position: relative;
}

.company_overview_left_section_img_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  top: 50%;
  cursor: pointer;
}

.company_overview_left_section_img_wrapper::after {
  content: "";
  width: 354.75px;
  height: 354.75px;
  background: url(../../../assets/images/about_section_ovreview2_bg.png) center
    no-repeat;
  background-size: cover;
  top: 9%;
  position: absolute;
  left: 17%;
}

.company_overview_left_section_img_wrapper::before {
  content: "";
  width: 391.89px;
  height: 391.89px;
  border: 1px;
  background: url(../../../assets/icons/about_section_round_circle.svg);
  position: absolute;
  top: 4.29%;
  left: 13.98%;
  animation: rotate 7s linear infinite;
}

.company_overview_left_section img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

.company_overview_right_section {
  width: 574px;
  height: auto;
  margin-left: -50px;
}

.company_overview_right_section_top {
  gap: 30px;
}

.company_overview_right_section_top_title p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: left;
  letter-spacing: 5%;
  color: var(--TopBar-Light-Green);
  text-transform: uppercase;
}

.company_overview_right_section_top_title h3,
.company_overview_right_section_bottom_title h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;
  color: var(--black);
}

.company_overview_right_section_top_description ul li,
.company_overview_right_section_bottom_description ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: justify;
  margin-left: -20px;
  color: var(--gray);
  padding: 0px 65px 0px 0px;
}

.company_overview_right_section_top_description ul li span {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: var(--TopBar-Dark-Green);
  text-transform: uppercase;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.company_overview_right_section_top_description .read_more_button {
  border: none;
  color: var(--TopBar-Light-Green);
  cursor: pointer;
  font-size: 16px;
  background: none;
}

.company_overview_right_section_top_description .read_more_button:hover {
  color: var(--TopBar-Dark-Green);
}
/*================================ADD MEDIA QUERY==================================== */

@media (max-width: 1440px) {
 

  .company_overview_left_section {
    width: 50%;
    height: 100%;
  }

  .company_overview_right_section {
    width: 50%;
  }

  .company_overview_right_section_top_title p,
  .company_overview_right_section_top_title h3,
  .company_overview_right_section_bottom_title {
    font-size: 32px;
  }
}

@media (max-width: 1024px) {
  .company_overview_main_container {
    width: 90%;
    flex-direction: column;
    gap: 20px;
  }

  .company_overview_left_section {
    width: 80%;
    height: auto;
    justify-content: center;
    display: flex;
    margin-left: 150px;
  }

  .company_overview_left_section_img_wrapper {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .company_overview_left_section_img_wrapper::after {
    width: 350px;
    height: 350px;
  }

  .company_overview_left_section_img_wrapper::before {
    display: none;
  }

  .company_overview_right_section {
    width: 100%;
    margin: 0;
  }

  .company_overview_right_section_top_title p,
  .company_overview_right_section_top_title h3,
  .company_overview_right_section_bottom_title {
    font-size: 24px;
  }
  .company_overview_right_section {
    margin-left: 0px;
  }
  .company_overview_right_section_top_description ul li,
  .company_overview_right_section_bottom_description ul li {
    padding: 0px 15px 0px 0px;
  }
}

@media (max-width: 768px) {
  .company_overview_main_container {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .company_overview_left_section {
    width: 70%;
    height: auto;
    justify-content: center;
    display: flex;
    margin: 0 auto;
  }

  .company_overview_left_section_img_wrapper::after {
    width: 254.75px;
    height: 254.75px;
  }

  .company_overview_left_section_img_wrapper {
    width: 320px;
    height: 100%;
    margin-left: -35px;
  }
  .company_overview_left_section_img_wrapper img {
    width: auto;
    height: auto;
  }

  .company_overview_right_section {
    padding: 0 25px;
    width: 100%;
  }

  .company_overview_right_section_top_title p,
  .company_overview_right_section_top_title h3,
  .company_overview_right_section_bottom_title {
    font-size: 20px;
  }

  .company_overview_right_section_top_description ul li,
  .company_overview_right_section_bottom_description ul li {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .company_overview_main_container {
    width: 100%;
    gap: 5px;
  }

  .company_overview_left_section {
    width: 100%;
  }

  .company_overview_right_section {
    width: 100%;
    margin-top: -50px;
    padding: 0px 5px;
  }

  .company_overview_right_section_top_title p,
  .company_overview_right_section_top_title h3,
  .company_overview_right_section_bottom_title {
    font-size: 18px;
  }

  .company_overview_right_section_top_description ul li,
  .company_overview_right_section_bottom_description ul li {
    font-size: 12px;
  }
}

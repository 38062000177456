* {
  box-sizing: border-box;
}

.experience_main_container {
  width: 100%;
  /* max-width: 1440px; */
  height: 678px;
  margin: auto;
  text-align: center;
  background-image: url(../../../assets/images/Experties_bg.jpg);
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 52px;
}

.experience_left_side {
  width: 453px;
  height: 478px;
  gap: 52px;
}

.experience_left_side_top_overview {
  width: 85%;
  height: 119px;
  gap: 8px;
  text-align: left;
}

.experience_left_side_top_overview p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 5%;
  color: var(--white);
  text-transform: uppercase;
}

.experience_left_side_top_overview h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: var(--white);
}

.experience_left_side_bottom_features {
  width: 453px;
  height: 307px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 52px;
}

.experience_left_side_bottom_feature {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.experience_left_side_bottom_feature_left_side_icon img:hover {
  transform: none;
  box-shadow: none;
  cursor: default;
}

.experience_left_side_bottom_feature_right_side_text {
  margin-left: 25px;
  gap: 10px;
}

.experience_left_side_bottom_feature_right_side_text h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 25.2px;
  color: var(--white);
  margin: 0 auto;
}

.experience_left_side_bottom_feature_right_side_text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  margin: 0 auto;
  color: var(--light-gray);
}

.experience_right_side {
  width: 550px;
  height: 478px;
  gap: 10px;
  background-image: url(../../../assets/images/Experties_right_side_bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 8px;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.experience_right_side_information {
  width: 403px;
  height: 140px;
  display: flex;
  justify-content: center;
  margin-top: 200px;
  animation: slideUp 0.6s ease-out;
}

@keyframes slideUp {
  from {
    width: 300px;
    height: 300px;
    transform: translateX(80px);
    transform: translateY(0px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.experience_right_side_information_1 {
  background-color: var(--white);
  width: 140px;
  height: 140px;
  border-radius: 4px 0px 0px 4px;
  gap: 2px;
}

.experience_right_side_information_2 {
  background-color: var(--white);
  width: 124px;
  height: 140px;
  gap: 2px;
}

.experience_right_side_information_3 {
  background-color: var(--white);
  width: 139px;
  height: 140px;
  gap: 2px;
  border-radius: 0px 4px 4px 0px;
}

.experience_right_side_information h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--black);
  margin-top: 40px;
}

.experience_right_side_information p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: var(--gray);
  margin: -1px;
  margin-top: -45px;
}

/*========================================= ADD MEDIA QUERY=======================================  */

@media (max-width: 1024px) {
  .experience_main_container {
    flex-direction: column;
    height: auto;
    padding: 15px;
  }

  .experience_left_side,
  .experience_right_side {
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
  }

  .experience_left_side_bottom_features {
    width: 100%;
    height: auto;
  }

  .experience_right_side_information {
    flex-direction: row;
    width: 550px;
    margin: 0 auto;
    height: 450px;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }
  .experience_right_side_information_1 {
    margin-top: 250px;
    text-align: center;
    justify-content: center;
  }
  .experience_right_side_information_2 {
    margin-top: 250px;
    text-align: center;
    justify-content: center;
  }
  .experience_right_side_information_3 {
    margin-top: 250px;
    text-align: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .experience_left_side_top_overview h3 {
    font-size: 24px;
    line-height: 30px;
  }

  .experience_left_side_bottom_feature_left_side_icon img {
    width: 40px;
    height: 40px;
  }

  .experience_left_side_bottom_feature_right_side_text h6 {
    font-size: 14px;
  }

  .experience_left_side_bottom_feature_right_side_text p {
    font-size: 14px;
  }

  .experience_right_side_information h6 {
    font-size: 16px;
    margin-top: 50px;
  }

  .experience_right_side_information p {
    font-size: 12px;
  }
  .experience_left_side_bottom_features {
    margin-top: 0px;
  }

  .experience_left_side_bottom_feature {
    margin-top: 20px;
  }

  .experience_left_side_bottom_feature_left_side_icon img {
    width: 50px;
    height: 50px;
    transition: none;
  }

  .experience_left_side_bottom_feature_left_side_icon img:hover {
    width: 50px;
    height: 50px;
    transform: none;
    box-shadow: none;
    border: none;
  }
}

@media (max-width: 520px) {
  .experience_right_side_information {
    margin-top: -20px;
  }
}

@media (max-width: 480px) {
  .experience_left_side_top_overview h3 {
    font-size: 18px;
  }

  .experience_left_side_bottom_feature_left_side_icon img {
    width: 30px;
    height: 30px;
  }

  .experience_left_side_bottom_feature_right_side_text h6 {
    font-size: 12px;
  }

  .experience_left_side_bottom_feature_right_side_text p {
    font-size: 12px;
  }

  .experience_right_side_information {
    width: 360px;
    align-items: center;
    /* margin: 0 auto; */
    margin-top: -50px;
  }
  .experience_right_side_information h6 {
    font-size: 14px;
  }

  .experience_right_side_information p {
    font-size: 10px;
  }
  .experience_left_side_bottom_features {
    margin-top: -20px;
  }

  .experience_left_side_bottom_feature {
    margin-top: 12px;
  }
  .experience_right_side {
    margin: 0;
    margin-top: -45px;
  }
}
@media (max-width: 460px) {
  .experience_right_side_information {
    margin-top: -65px;
  }
}
@media (max-width: 440px) {
  .experience_right_side_information {
    margin-top: -88px;
  }

  .experience_main_container {
    margin-top: -50px;
  }
}
@media (max-width: 420px) {
  .experience_right_side_information {
    margin-top: -90px;
  }
}
@media (max-width: 400px) {
  .experience_right_side_information {
    width: 340px;
    align-items: center;
    margin: 0 auto;
    margin-top: -105px;
  }
}
@media (min-width: 0px) and (max-width: 380px) {
  .experience_left_side_top_overview {
    width: auto;
  }
  .experience_right_side_information {
    width: 300px;
    align-items: center;
    margin: 0 auto;
    margin-top: -150px;
  }
}
@media (max-width: 330px) {
  .experience_right_side_information {
    width: 240px;
    align-items: center;
    margin: 0 auto;
    margin-top: -200px;
  }
}

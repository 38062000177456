@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.awards-container {
  display: flex;
  /* max-width: 1440px; */
  width: 100%;
  margin: 0 auto;
  padding: 55px;
  background: rgba(245, 252, 255, 1);
  gap: 74px;
  align-items: center;
  text-align: center;
  position: relative;
}

.left-content {
  position: relative;
  flex: 0 0 35%;
  max-width: 40%;
}

.left-content h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--TopBar-Light-Green);
  margin: 0;
}

.left-content h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  color: var(--black);
  margin: 0;
}

.left-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  color: var(--black);
  margin-top: 35px;
}

.right-content {
  flex: 0 0 60%;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

.scrollable-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 15px;
  width: 100%;
  transition: transform 0.5s ease;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.award-card {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  flex: 0 0 46%;
  cursor: pointer;
  gap: 16px;
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.award-card img {
  width: 74px;
  height: 74px;
  margin: 0 auto 12px;
}

.award-card h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: var(--gray);
  margin: 0 auto 15px;
}

.award-card h6 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.45px;
  letter-spacing: -0.154px;
  text-align: center;
  color: var(--gray);
  margin: 0 auto 2px;
}

.award-card p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 23.45px;
  letter-spacing: -0.154px;
  text-align: center;
  color: var(--TopBar-Dark-Green);
  margin: 0 auto;
}


/* ADD DIALOG BOX CSS:   */

.modal {
  position: fixed;
  top: 2%;
  left: 20%;
  width: 60%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: var(--gray);
  animation: fadeIn 0.5s forwards;
  z-index: 1000000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
  rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
  rgba(0, 0, 0, 0.17) 0px 12px 
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-content {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width: 80%;
  height: 80%;
  overflow: auto;
  animation: slideIn 0.5s ease-out; /* Slide-in animation */
}
@keyframes slideIn {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}

.modal-image {
  width: 100%;
 
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: var(--black);
  cursor: pointer;
  transition: 0.6s;
}

.close:hover {
  color: var(--TopBar-Dark-Green);
}




/*=====================================ADD MEDIA QUERY====================================== */

@media (max-width: 768px) {

  .modal {
    top:8%;
    left: 15%;
    width: 80%;
  }
  }

@media (max-width: 480px) {
  .awards-container {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .left-content,
  .right-content {
    flex: 1 1 auto;
    max-width: 100%;
  }

  .left-content h3 {
    font-size: 28px;
    line-height: 32px;
  }

  .left-content p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }

  .scrollable-container {
    gap: 10px;
    width: 125%;
  }

  .award-card {
    flex: 0 0 80%;
    padding: 10px 25px 10px 25px;
  }

  .award-card img {
    width: 60px;
    height: 60px;
  }

  .award-card h4 {
    font-size: 16px;
    line-height: 20px;
  }

  .award-card h6 {
    font-size: 12px;
  }

  .award-card p {
    font-size: 12px;
  }

  
  .modal {
    top: 29%;
    left: 5%;
    width: 90%;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .awards-container {
    flex-direction: column;
    padding: 40px;
    gap: 30px;
  }

  .left-content,
  .right-content {
    flex: 1 1 auto;
    max-width: 100%;
    border: none;
  }

  .left-content h3 {
    font-size: 32px;
    line-height: 36px;
  }

  .left-content p {
    font-size: 15px;
    line-height: 22px;
    margin-top: 25px;
  }

  .scrollable-container {
    gap: 5px;
    width: 100%;
  }

  .award-card {
    flex: 0 0 45%;
    padding: 15px;
  }

  .award-card img {
    width: 68px;
    height: 68px;
  }

  .award-card h4 {
    font-size: 17px;
    line-height: 22px;
  }

  .award-card h6 {
    font-size: 13px;
  }

  .award-card p {
    font-size: 13px;
  }
}

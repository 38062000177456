:root {
  --TopBar-Light-Green: #72b01d;
  --white: #ffffff;
  --black: #000000;
  --TopBar-Dark-Green: #3f7d20;
  --gray: #828282;
  --light-gray: #ededed;
  --ghost-white: #f5fcff;
  --footer-bg: #272932;
  --Faqs-bg: #357960;
  --border: #D3D3D3;
}

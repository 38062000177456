.team-section {
  width: 100%;
  max-width: 1440px;
  text-align: center;
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 10px;
}

.team-paragraph {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  color: var(--TopBar-Light-Green);
  margin: 0 auto;
}

.team-heading {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: var(--black);
  margin: 0;
}

.team-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 34px;
}

.team-card {
  background-color: var(--TopBar-Dark-Green);
  overflow: hidden;
  width: 280px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.team-image-container {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  /* height: 50vh; */
  background-size: cover cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.team-image {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.team-overlay {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: auto;
  height: 100%;
  background-color: rgba(63, 125, 32, 0.7);
  color: var(--white);
  padding: 20px;
  transition: bottom 0.3s ease-in-out;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  text-align: justify;
}

.team-card:hover .team-overlay {
  bottom: 0;
}

.team-info {
  padding: 15px;
  text-align: center;
  margin: 0 auto -25px;
  height: auto;
}

.team-info h3 {
  font-size: 22px;
  font-weight: 500;
  color: var(--white);
  margin: 0;
}

.team-info p {
  font-size: 18px;
  font-weight: 400;
  color: var(--white);
}

@media (max-width: 425px) {
  .team-section {
    margin-bottom: 50px;
  }
}

.electronic_overview_main_container {
  width: 90%;
  /* max-width: 1165px; */
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding: 0 15px;
  margin-top: 30px;
}

.electronic_overview_title {
  position: relative;
}

.electronic_overview_title::before {
  content: "";
  background: url(../../../../assets/icons/about_section_ovreview_dots.svg);
  width: 443px;
  height: 590px;
  position: absolute;
  top: -85%;
  left: -8.5%;
}

.electronic_overview_title p {
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  color: var(--TopBar-Light-Green);
  margin: 0 auto -30px;
  text-transform: uppercase;
}

.electronic_overview_title h3 {
  color: var(--black);
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}

.electronic_overview_content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: var(--gray);
  text-align: justify;
  justify-content: center;
}

/*=====================================ADD MEDIA QUERY====================================== */

@media (max-width: 768px) {
  .electronic_overview_title h3 {
    font-size: 32px;
    line-height: 36px;
  }

  .electronic_overview_title p {
    font-size: 16px;
    margin: 0 auto -20px;
  }

  .electronic_overview_content p {
    font-size: 16px;
    line-height: 22px;
  }

  .electronic_overview_title::before {
    width: 300px;
    height: 400px;
    left: -5%;
    top: 0;
  }
  .electronic_overview_title::before {
  display: none;
  }
}

@media (max-width: 480px) {
  .electronic_overview_title h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .electronic_overview_title p {
    font-size: 14px;
    margin: 0 auto -15px;
  }

  .electronic_overview_content p {
    font-size: 14px;
    line-height: 20px;
  }

  .electronic_overview_title::before {
    width: 200px;
    height: 300px;
    left: -2%;
    top: -10%;
  }
}

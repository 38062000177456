.case_mgmt_our_service_main_container {
  width: 100%;
  /* max-width: 1440px; */
  height: auto;
  margin: 0 auto;
  padding: 25px 25px 25px 0px;
  background: url(../../../../assets/icons/service_section_our_services_bg.svg),
    linear-gradient(180deg, #72b01d 0%, #357960 100%);
  position: relative;
  padding-bottom: 100px;
}

.case_mgmt_our_service_title p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--white);
  text-align: center;
  margin: 0 auto;
}

.case_mgmt_our_service_title h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: var(--white);
  margin: 0 auto -10px;
  text-align: center;
}

.case_mgmt_our_service_menu_bar {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 70px;
  margin: 0 auto;
}

.case_mgmt_our_service_left_side_menu {
  width: 100%;
  max-width: 1160px;
  height: auto;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}

/* Desktop Menu */

.desktop_menu {
  display: block;
  height: auto;
  margin: 0 auto;
  align-items: center;
}

.desktop_menu ul {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.desktop_menu ul li {
  color: var(--white);
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  cursor: pointer;
  position: relative;
  margin-left: 32px;
  transition: font-size, background-color;
  line-height: 1;
}

.desktop_menu ul li.selected {
  color: var(--TopBar-Dark-Green);
  font-size: 18px;
  font-weight: 600;
  background-color: var(--white);
  width: 320px;
  height: 57px;
  max-height: 100%;
  border-radius: 6px;
  text-align: left;
  display: flex;
  margin: 0 auto;
  padding: 5px;
  margin-left: 12px;
  align-items: center;
}
.desktop_menu ul li:hover::before {
  height: 14px;
}

/* Dropdown Button */
.dropdown_button {
  background-color: var(--TopBar-Dark-Green);
  color: var(--white);
  border: none;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 5px;
  width: 95%;
  display: none;
  margin: 0 auto;
  margin-left: 25px;
  text-align: center;
}

/* Dropdown Menu */
.dropdown_menu {
  padding: 0;
  margin: 0 auto;
  position: absolute;
  top: 100%;
  left: 2.5%;
  width: 72%;
  background-color: var(--white);
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  z-index: 1000;
  display: none;
}

.dropdown_menu.open {
  display: block;
}

.dropdown_menu li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e7e7e7;
  font-size: 18px;
  list-style: none;
}

.dropdown_menu li:hover {
  background-color: #f0f0f0;
}

.case_mgmt_our_service_right_side_services {
  border: 1px solid #e7e7e7e7;
  background-color: var(--white);
  flex: 1;
  text-align: left;
  border-radius: 10px;
  padding: 40px;
  margin: 0px 0px 0px 20px;
  height: 510px;
  animation: fadeInUp 2s ease-out forwards;
  animation-delay: 0.5s;
}
.case_mgmt_our_service_right_side_services::-webkit-scrollbar {
  display: none;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.case_mgmt_our_service_right_side_services_title p {
  font-size: 30px;
  font-weight: 600;
  color: var(--Faqs-bg);
  margin: 0 auto;
}

.case_mgmt_our_service_right_side_services_title {
  display: flex;
  align-items: center;
  gap: 10px;
}
.case_mgmt_our_service_horizontal_line {
  flex-grow: 1;
  height: 2px;
  background-color: var(--Faqs-bg);
  opacity: 60%;
}

.case_mgmt_our_service_right_side_services_content p {
  font-size: 18px;
  color: var(--gray);
  text-align: left;
  line-height: 1.5;
}

.case_mgmt_our_service_right_side_services_content span {
  font-size: 26px;
  color: var(--TopBar-Dark-Green);
}

.case_mgmt_our_service_right_side_services_content ul li {
  font-size: 18px;
  color: var(--gray);
}

.out_service_button_icon {
  position: absolute;
  gap: 10px;
  display: flex;
  margin-left: 220px;
  margin-top: 50px;
}
.case_mgmt_our_service_left_side_up_button {
  display: none;
}

.case_mgmt_our_service_left_side_down_button {
  display: none;
}

.case_mgmt_our_service_selected_indicator {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--TopBar-Dark-Green);
  margin-right: 19px;
  display: inline-block;
}

.case_mgmt_our_service_grid_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 22px;
  padding: 15px;
  width: 100%;
  text-align: center;
}

.service_item {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s;
  float: left;
  color: var(--white);
  font-size: 18px;
  padding: 17px;
  position: relative;
}

.service_item.selected {
  border-radius: 6px;
  box-shadow: 2px #e7e7e7;
  background-color: #f0f0f0;
  color: var(--TopBar-Dark-Green);
  font-size: 18px;
  font-weight: bold;
}
.service_item_content {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: left;
}

.service_dot {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: var(--white);
  border-radius: 50%;
  margin-right: 10px;
  transition: background-color 0.3s;
  text-align: left;
  float: left;
  position: absolute;
  margin-left: -340px;
  margin-top: 7px;
}

.dot-active {
  background-color: var(--TopBar-Dark-Green);
}

/*=====================================ADD MEDIA QUERY====================================== */

@media (max-width: 1024px) {
  .case_mgmt_our_service_right_side_services {
    border: 1px solid #e7e7e7e7;
    background-color: var(--white);
    flex: 1;
    text-align: left;
    border-radius: 10px;
    padding: 10px 5px 0px 15px;
    overflow-y: auto;
    margin: 0;
    height: auto;
    margin-left: 26px;
  }
  .out_service_button_icon {
    display: none;
  }

  /* add  */
  .service_dot {
    margin-left: -280px;
    margin-top: 7px;
  }

  .case_mgmt_our_service_grid_container {
    gap: 8px;
  }

  .service_item {
    padding: 10px 18px;
  }

  .service_item {
    font-size: 16px;
  }

  .service_item.selected {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .dropdown_button {
    display: block;
    padding: 10px;
    font-size: 16px;
    margin: 0;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;
  }
  .dropdown_menu_container {
    justify-content: space-evenly;
    display: flex;
    gap: 15px;
    padding-left: 20px;
  }

  .desktop_menu {
    display: none;
    width: 96%;
    margin-left: 8.5px;
    top: 100%;
  }
  .case_mgmt_our_service_grid_container {
    display: none;
  }

  .case_mgmt_our_service_menu_bar {
    flex-direction: column;
    align-items: center;
  }

  .case_mgmt_our_service_left_side_menu {
    width: 100%;
    margin-top: 20px;
  }

  .dropdown_menu {
    width: 100%;
  }

  .case_mgmt_our_service_right_side_services {
    padding: 20px;
    margin: 0px 0px 20px 25px;
  }

  .case_mgmt_our_service_title h3 {
    font-size: 30px;
  }

  .case_mgmt_our_service_left_side_up_button {
    display: block;
    width: 40px;
    height: 58px;
    border-radius: 14px;
    background-color: var(--white);
    padding: 9.5px 15px 9.5px 15px;
    border: 1px solid var(--white);
    cursor: pointer;
  }
  .case_mgmt_our_service_left_side_up_button img {
    height: 25px;
    width: 22px;
    margin-left: -5px;
    margin-top: 8px;
  }
  .case_mgmt_our_service_left_side_down_button {
    display: block;
    width: 40px;
    height: 58px;
    background: transparent;
    padding: 9.5px 15px 9.5px 15px;
    border: 1px solid var(--white);
    padding: 9.5px 15px 9.5px 15px;
    border-radius: 14px;
    cursor: pointer;
  }
  .case_mgmt_our_service_left_side_down_button img {
    height: 25px;
    width: 22px;
    margin-left: -6px;
    margin-top: 8px;
  }
}

@media (max-width: 480px) {
  .case_mgmt_our_service_title p {
    font-size: 14px;
  }
  .case_mgmt_our_service_right_side_services_content ul li {
    margin-left: -20px;
  }

  .case_mgmt_our_service_title h3 {
    font-size: 24px;
    line-height: 30px;
  }

  .dropdown_button {
    font-size: 16px;
    padding: 8px;
    margin: 0;
    width: 100%;
    font-weight: 500;
  }

  .case_mgmt_our_service_right_side_services_title p {
    font-size: 18px;
    text-align: center;
  }

  .case_mgmt_our_service_right_side_services_content p {
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    margin-top: 5px;
  }

  .case_mgmt_our_service_right_side_services_content span {
    font-size: 20px;
  }
  .dropdown_menu_container {
    justify-content: center;
    display: flex;
    padding-left: 20px;
    gap: 5px;
  }
}

@media (max-width: 320px) {
  .case_mgmt_our_service_right_side_services_content p {
    font-size: 12px;
    padding-right: 2px;
  }
  .case_mgmt_our_service_right_side_services_content ul li {
    font-size: 13px;
  }
}

/* dots  */
@media (max-width: 1190px) {
  .service_dot {
    margin-left: -330px;
    margin-top: 7px;
  }
}

@media (max-width: 1120px) {
  .service_dot {
    margin-left: -290px;
    margin-top: 7px;
  }
}

@media (max-width: 1010px) {
  .service_dot {
    margin-left: -273px;
    margin-top: 7px;
  }
}
@media (max-width: 945px) {
  .service_dot {
    margin-left: -253px;
    margin-top: 7px;
  }
}

@media (max-width: 900px) {
  .service_dot {
    margin-left: -233px;
    margin-top: 7px;
  }
}

@media (max-width: 850px) {
  .service_dot {
    margin-left: -225px;
    margin-top: 7px;
  }
}

.case_mgmt_our_service_item_content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.case_mgmt_our_service_item_dot {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.case_mgmt_our_service_item_dot img {
  width: 28px;
  height: 28px;
}

.service_item.selected .case_mgmt_our_service_item_dot img {
  color: var(--gray);
}

.industries_we_server_main_container {
  /* max-width: 1440px; */
  width: 100%;
  height: 100%;
  gap: 48px;
  margin: 0 auto;
  padding: 30px;
  margin-top: 60px;
  margin-bottom: 30px;
  position: relative;
}
.industries_we_server_title {
  position: relative;
}
.industries_we_server_title::before {
  content: "";
  width: 220px;
  height: 200px;
  background: url(../../../assets/icons/indutry_section_sqare_type.svg)
    no-repeat;
  background-position: left;
  opacity: 20%;
  position: absolute;
  left: -2.5%;
  top: -140%;
}
.industries_we_server_title::after {
  content: "";
  width: 31px;
  height: 157px;
  background: url(../../../assets/icons/indutry_section_dots.svg) no-repeat;
  background-position: left;
  opacity: 20%;
  position: absolute;
  top: -150%;
  left: 98%;
}

.industries_we_server_title p {
  font-size: 34px;
  font-weight: 600;
  line-height: 57.6px;
  text-align: center;
  color: var(--black);
  margin: 0 auto;
}

.industries_we_server_content {
  /* max-width: 1437px; */
  width: 100%;
  height: 100%;
  gap: 50px;
}

.industries-list {
  list-style: none;
  padding: 0;
}

/* Add these styles to your existing CSS */

.industry_bg {
  width: 180px;
  height: 140px;
  border-radius: 20px;
  background-color: var(--TopBar-Light-Green);
  display: none;
  position: absolute;
  left: 70%;
  padding: 10px;
}

.industry_bg_image {
  width: 120px;
  height: 120px;
  z-index: 100;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
}

.industry_item .industry_image {
  display: none;
}

.industry_item:hover .industry_bg {
  display: block;
}
.industry_item:hover {
  background: linear-gradient(90deg, #72b01d 0%, #3f7d20 34.83%);
}
.industry_item:hover .industry_name h4 {
  color: var(--white);
}
.industry_item:hover .industry_name p {
  color: var(--white);
}

.industry_item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
  justify-content: space-between;
  position: relative;
}

.industry_item.active h4,
.industry_item.active p {
  color: var(--white);
}

.industry_details {
  display: flex;
  /* max-width: 1440px; */
  width: 100%;
  height: 110px;
  justify-content: space-between;
  gap: 375px;
  padding: 0 20px;
  /* transition: 0.6s; */
}

.industry_details:hover {
  background: linear-gradient(90deg, #72b01d 0%, #3f7d20 34.83%);
  /* width: 100%;
  height: 112px; */
  padding: 0px 20px;
}

.industry_name {
  display: flex;
  /* max-width: 1437px; */
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.industry_name h4 {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.4px;
  text-align: left;
  color: var(--black);
  width: 270px;
  align-items: center;
  display: flex;
}

.industry_name p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--gray);
  margin: 0 auto;
  align-items: center;
  display: flex;
  width: 490px;
}

.banking {
  max-width: 210px;
  width: 100%;
  background-color: var(--TopBar-Light-Green);
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  left: 100%;
  top: -25%;
  border-radius: 20px;
  vertical-align: middle;
  gap: 25px;
  padding: 25px 0px 25px 0px;
}

.banking img {
  width: 85px;
  height: 85px;
  padding: 0;
}

.industry_arrow_icon {
  width: 48px;
  height: 48px;
  border: 1px solid var(--TopBar-Light-Green);
  border-radius: 100%;
  position: relative;
  padding: 20px;
  top: 30%;
  left: -5%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
}

.industry_arrow_icon img {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 18%;
  left: 13%;
  transition: transform 0.3s;
}

.industry_arrow_icon img:hover {
  rotate: 30deg;
  transition: 0.3s;
}

.industry_arrow_icon.rotated img {
  transform: rotate(30deg);
}

.industries_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
}

.industries_popup_content {
  background: var(--white);
  width: 100%;
  max-width: 900px;
  height: auto;
  border-radius: 20px;
  padding: 32px;
  gap: 32px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.industries_popup_content h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: var(--black);
  margin: 0;
  padding: 0;
}

.industries_popup_content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--black);
  margin-top: -15px;
}

.industries_close_popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bold;
}

.industries_services_served h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: var(--black);
  margin-top: -25px;
}

.industries_services_served_image {
  display: flex;
  gap: 50px;
}

.industries_services_served_image p {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: var(--white);
}

.read_more_button {
  border: none;
  color: var(--black);
  cursor: pointer;
  font-size: 16px;
  background: none;
  transition: 0.3s;
  font-weight: 600;
}
.read_more_button:hover {
  color: red;
}

.industries_services_served_list {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px;

}

.service_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.service_item img {
  width: 30px;
  height: 30px;
}

.service_item p {
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  margin-top: 15px;
}

/*========================================= ADD MEDIA QUERIES =========================================*/
@media (max-width: 1024px) {
  .industries_we_server_title::before {
    display: none;
  }
  .industries_we_server_title::after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .industries_we_server_title p {
    font-size: 36px;
  }

  .industries_we_server_content {
    height: auto;
  }

  .industry_details {
    gap: 20px;
  }
  .industry_name {
    max-width: 1437px;
  }

  .industry_name p {
    width: 50%;
    line-height: inherit;
    margin-top: -2px;
  }

  .industries_popup_content {
    height: auto;
    padding: 16px;
  }

  .industries_popup_content h3 {
    font-size: 32px;
  }

  .industries_popup_content p {
    font-size: 16px;
  }

  .industries_services_served h4 {
    font-size: 24px;
  }

  .industries_services_served_image p {
    font-size: 18px;
  }
  .industry_bg,
  .industry_item .industry_image,
  .industry_item:hover .industry_bg {
    display: none;
  }
}

@media (max-width: 992px) {
  .industries_we_server_title p {
    font-size: 28px;
  }

  .industry_item {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 30px;
  }

  .industry_name {
    flex-direction: column;
  }

  .industry_name h4 {
    font-size: 20px;
  }

  .industry_name p {
    width: auto;
  }

  .industries_popup_content {
    padding: 16px;
  }

  .industries_popup_content h3 {
    font-size: 28px;
  }

  .industries_popup_content p {
    font-size: 14px;
  }

  .industries_services_served h4 {
    font-size: 22px;
  }

  .industries_services_served_image {
    flex-direction: column;
  }

  .industries_services_served_image p {
    font-size: 16px;
  }

  .industries_services_served_list{
    overflow-x: scroll;
    scrollbar-width: thin;
    scroll-behavior: smooth;
  }
}

@media (max-width: 768px) {
  .industries_we_server_title p {
    font-size: 24px;
  }

  .industry_item {
    padding: 10px;
  }

  .industry_name h4 {
    font-size: 18px;
  }

  .industry_name p {
    font-size: 14px;
  }

  .industries_popup_content {
    width: 85%;
    max-width: none;
    padding: 12px;
    margin-top: 17px;
  }

  .industries_popup_content h3 {
    font-size: 23px;
  }

  .industries_popup_content p {
    font-size: 15px;
  }

  .industries_services_served h4 {
    font-size: 21px;
  }

  .industries_services_served_image p {
    font-size: 16px;
    text-align: left;
  }

  .industry_arrow_icon.rotated img {
    transform: rotate(30deg);
  }
  .industry_image {
    width: 80px;
  }
  .industry_arrow_icon img:hover {
    rotate: 0deg;
    transition: 0.3s;
  }
}

@media (max-width: 576px) {
  .industries_we_server_title p {
    font-size: 20px;
  }

  .industry_item {
    padding: 8px;
  }

  .industry_name h4 {
    font-size: 16px;
  }

  .industry_name p {
    font-size: 12px;
    width: auto;
  }

  .industries_popup_content {
    width: 95%;
    padding: 10px;
  }

  .industries_popup_content h3 {
    font-size: 20px;
  }

  .industries_popup_content p {
    font-size: 12px;
    line-height: 18px;
    padding: 0px 6px 0px 6px;
  }

  .industries_services_served h4 {
    font-size: 18px;
  }

  .industries_services_served_image p {
    font-size: 14px;
  }
  .industry_arrow_icon {
    top: 20%;
    align-items: center;
    display: flex;
  }

  .industry_name p {
    font-size: 12px;
    width: auto;
    margin-top: -15px;
    line-height: 1.5;
  }
  .industry_name h4 {
    align-items: center;
    display: flex;
    /* margin-top: 30px; */
    font-size: 18px;
  }
  .industries_we_server_main_container {
    padding: 10px;
  }
}
@media (max-width: 360px) {
  .industry_arrow_icon {
    top: 3%;
    align-items: center;
    display: flex;
  }

  .industry_name p {
    font-size: 12px;
    width: auto;
    margin-top: -15px;
    line-height: 1.5;
  }

  .industries_we_server_main_container {
    padding: 0px;
  }
  .industry_arrow_icon {
    left: -12%;
  }
}
@media (max-width: 325px) {
  .industry_arrow_icon {
    left: -17%;
  }
}
@media (max-width: 300px) {
  .industry_arrow_icon {
    left: -25%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.banner_main_container {
  /* max-width: 100%; */
  width: 100%;
  height: 150px;
  display: flex;
  background: linear-gradient(
      90.93deg,
      #3f7d20 18.18%,
      rgba(9, 18, 66, 0.2) 64.92%
    ),
    url(../../../assets/icons/about_section_banner_image.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: left;
  color: white;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.banner_subtext {
  font-family: "Rubik", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 65.18px;
  margin-left: 100px;
}

/*================================ADD MEDIA QUERY==================================== */

@media (max-width: 1200px) {
  .banner_main_container {
    height: 200px;
    font-size: 20px;
  }

  .banner_subtext {
    font-size: 45px;
    margin-left: 50px;
  }
}

@media (max-width: 992px) {
  .banner_main_container {
    height: 180px;
    font-size: 18px;
    padding: 0 15px;
  }

  .banner_subtext {
    font-size: 40px;
    margin-left: 30px;
  }
}

@media (max-width: 768px) {
  .banner_main_container {
    height: 100px;
    font-size: 16px;
    padding: 0 5px;
  }

  .banner_subtext {
    font-size: 35px;
    margin-left: 20px;
  }
}

@media (max-width: 576px) {
  .banner_main_container {
    height: 65px;
    font-size: 14px;
    padding: 0 5px;
  }

  .banner_subtext {
    font-size: 24px;
    margin-left: 10px;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 355px) {
  .banner_main_container {
    height: 65px;
    font-size: 14px;
    padding: 0 5px;
  }

  .banner_subtext {
    font-size: 18px;
    margin-left: 10px;
    text-align: center;
    width: 100%;
  }
}

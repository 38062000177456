.security_our_core_services_main_container {
  max-width: 14400px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
}

.security_our_core_services_main_container::before {
  position: absolute;
  content: "";
  background: url(../../../../assets/icons/service_section_half_round_circle.svg);
  background-repeat: no-repeat;
  width: 400px;
  height: 500px;
  top: 50%;
  left: 0%;
  background-position: top left;
  background-size: contain;
  z-index: -1;
}

.security_our_core_services_main_container::after {
  position: absolute;
  content: "";
  background: url(../../../../assets/icons/choose_us_section_round.svg);
  background-repeat: no-repeat;
  width: 200px;
  height: 180px;
  top: -2%;
  right: 0%;
  background-position: center center;
  background-size: cover;
  z-index: -1;
}

.security_our_core_services_p {
  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--TopBar-Light-Green);
  text-transform: uppercase;
}

.security_our_core_services_h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  margin-top: -3px;
}

.security_our_core_services_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  position: relative;
}

.security_our_core_services_card {
  max-width: 310px;
  padding: 15px;
  text-align: center;
  flex: 1 1 auto;
  margin: 10px;
}

.security_our_core_services_card_icon {
  width: 50px;
  height: 50px;
}

.security_our_core_services_card h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: var(--black);
  margin-top: 15px;
}

.security_our_core_services_card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--gray);
  margin-top: 10px;
}

.security_our_core_services_card_arrow {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.5s;
}

.security_our_core_services_card_arrow:hover {
  filter: brightness(1.2);
  background-color: rgba(63, 125, 32, 0.1);
  box-shadow: 0.5px 0.5px 2px 4px var(--TopBar-Light-Green);
  border-radius: 100%;
}

.security_vertical_line1,
.security_vertical_line2,
.security_vertical_line3 {
  height: 45%;
  position: absolute;
  width: 1px;
  background-color: var(--gray);
  opacity: 30%;
}

.security_vertical_line1 {
  left: 36%;
}

.security_vertical_line2 {
  right: 36%;
}
.security_vertical_line3 {
  top: 52%;
  right: 50%;
}

.security_horizontal_line1 {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
}
/*=====================================ADD MEDIA QUERY====================================== */

@media (max-width: 1250px) {
  .security_our_core_services_main_container::before {
    display: none;
  }
  .security_our_core_services_main_container::after {
    display: none;
  }
}

@media (max-width: 1024px) {
  .security_horizontal_line1,
  .security_vertical_line1,
  .security_vertical_line2,
  .security_vertical_line3 {
    display: none;
  }
  .security_our_core_services_cards::after,
  .security_our_core_services_cards::before {
    display: none;
  }
}

@media (max-width: 768px) {
  .security_our_core_services_h3 {
    font-size: 28px;
    line-height: 32px;
  }
}

@media (max-width: 425px) {
  .security_our_core_services_cards {
    gap: 0px;
  }

  .security_our_core_services_card {
    margin-top: 1px;
    text-align: center;
    flex: 1 1 auto;
  }
}
